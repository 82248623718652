const HOST = process.env.API_HOST || 'https://erp.elorigenspa.com';
const API_VERSION = 'api/v1';
const buildEndpoint = (path: string) => `${HOST}/${API_VERSION}/${path}`;

export const EndPoints = {
    AUTH: {
        LOGIN: buildEndpoint('auths/login'),
        LOGOUT: buildEndpoint('auths/logout'),
        // PUSHER_AUTH: buildEndpoint('auths/pusher-auth'),
        VALIDATE_TOKEN: buildEndpoint('auths/validate-token'),
        GET_ROLES: buildEndpoint('auths/get-roles'),
        SEND_CODE_RECOVERY_PASSWORD: buildEndpoint('auths/send-code-recovery-password'),
        CHANGE_PASSWORD_BY_CODE: buildEndpoint('auths/change-password-by-code'),
        UPDATE_PROFILE: buildEndpoint('auths/update-profile'),
        UPDATE_PROFILE_PASSWORD: buildEndpoint('auths/update-profile-password')
        
    
    },
    ACCOUNT: {
        GET_ACCOUNTS: buildEndpoint('accounts/accounts'),
        STORE_ACCOUNT: buildEndpoint('accounts/accounts/store'),
        EDIT_ACCOUNT: buildEndpoint('accounts/accounts/:id/edit'),
        CREATE_ACCOUNT: buildEndpoint('accounts/accounts/create'),
        SHOW_ACCOUNT: buildEndpoint('accounts/accounts/:id/show'),
        UPDATE_ACCOUNT: buildEndpoint('accounts/accounts/:id/update'),
        DELETE_ACCOUNT: buildEndpoint('accounts/accounts/:id/delete'),
        ACTIVE_ACCOUNT: buildEndpoint('accounts/accounts/:id/active')
    },
    ACCOUNTING: {
        GET_ACCOUNTINGS_BY_SERVICE_MANIFEST_DETAIL: buildEndpoint(
            'accounts/accountings/:service_manifest_detail_id/by-service-manifest-detail'
        ),
        STORE_ACCOUNTING: buildEndpoint('accounts/accountings/store'),
        EDIT_ACCOUNTING: buildEndpoint('accounts/accountings/:id/edit'),
        SHOW_ACCOUNTING: buildEndpoint('accounts/accountings/:id/show'),
        UPDATE_ACCOUNTING: buildEndpoint('accounts/accountings/:id/update'),
        DELETE_ACCOUNTING: buildEndpoint('accounts/accountings/:id/delete')
    },
    BANKS: {
        GET_BANKS: buildEndpoint('banks/banks'),
        STORE_BANK: buildEndpoint('banks/banks/store'),
        CREATE_BANK: buildEndpoint('banks/banks/create'),
        EDIT_BANK: buildEndpoint('banks/banks/:id/edit'),
        SHOW_BANK: buildEndpoint('banks/banks/:id/show'),
        UPDATE_BANK: buildEndpoint('banks/banks/:id/update'),
        DELETE_BANK: buildEndpoint('banks/banks/:id/delete'),
        ACTIVE_BANK: buildEndpoint('banks/banks/:id/active')
    },
    BANK_ACCOUNT_REASONS: {
        GET_BANK_ACCOUNT_REASONS: buildEndpoint('banks/banks-account-reasons'),
        STORE_BANK_ACCOUNT_REASON: buildEndpoint('banks/banks-account-reasons/store'),
        EDIT_BANK_ACCOUNT_REASON: buildEndpoint('banks/banks-account-reasons/:id/edit'),
        SHOW_BANK_ACCOUNT_REASON: buildEndpoint('banks/banks-account-reasons/:id/show'),
        UPDATE_BANK_ACCOUNT_REASON: buildEndpoint('banks/banks-account-reasons/:id/update'),
        DELETE_BANK_ACCOUNT_REASON: buildEndpoint('banks/banks-account-reasons/:id/delete'),
        ACTIVE_BANK_ACCOUNT_REASON: buildEndpoint('banks/banks-account-reasons/:id/active')
    },
    BANK_ACCOUNT_TYPES: {
        GET_BANK_ACCOUNT_TYPES: buildEndpoint('banks/bank-account-types'),
        STORE_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/store'),
        CREATE_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/create'),
        EDIT_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/:id/edit'),
        SHOW_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/:id/show'),
        UPDATE_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/:id/update'),
        DELETE_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/:id/delete'),
        ACTIVE_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/:id/active')
    },
    BATCH: {
        GET_BATCHES: buildEndpoint('batches/batches'),
        STORE_BATCH: buildEndpoint('batches/batches/store'),
        EDIT_BATCH: buildEndpoint('batches/batches/:id/edit'),
        CREATE_BATCH: buildEndpoint('batches/batches/create'),
        SHOW_BATCH: buildEndpoint('batches/batches/:id/show'),
        UPDATE_BATCH: buildEndpoint('batches/batches/:id/update'),
        DELETE_BATCH: buildEndpoint('batches/batches/:id/delete'),
        ACTIVE_BATCH: buildEndpoint('batches/batches/:id/active'),
        GET_BY_DEPOT: buildEndpoint('batches/batches/:id/:depot_id/:category_id/get-by-depot'),
        GET_BY_PRODUCT: buildEndpoint('batches/batches/:product_id/get-by-product')
    },
    BATCH_DETAIL: {
        GET_BATCH_DETAILS_BY_BATCH: buildEndpoint('batches/batch-details/:batch_id/by-batch'),
        GET_BATCH_DETAIL_ACCOUNTINGS_BY_BATCH: buildEndpoint(
            'batches/batch-details/:batch_id/accounting-by-batch'
        ),
        STORE_BATCH_DETAIL: buildEndpoint('batches/batch-details/store'),
        EDIT_BATCH_DETAIL: buildEndpoint('batches/batch-details/:id/edit'),
        CREATE_BATCH_DETAIL: buildEndpoint('batches/batch-details/create'),
        SHOW_BATCH_DETAIL: buildEndpoint('batches/batch-details/:id/show'),
        UPDATE_BATCH_DETAIL: buildEndpoint('batches/batch-details/:id/update'),
        DELETE_BATCH_DETAIL: buildEndpoint('batches/batch-details/:id/delete')
    },
    BATCH_HOSTORY: {
        GET_BATCH_HOSTORIES_BY_BATCH: buildEndpoint('batches/batch-histories/:batch_id/by-batch')
    },
    BATCH_TYPE: {
        GET_BATCH_TYPES: buildEndpoint('batches/batch-types'),
        STORE_BATCH_TYPE: buildEndpoint('batches/batch-types/store'),
        EDIT_BATCH_TYPE: buildEndpoint('batches/batch-types/:id/edit'),
        CREATE_BATCH_TYPE: buildEndpoint('batches/batch-types/create'),
        SHOW_BATCH_TYPE: buildEndpoint('batches/batch-types/:id/show'),
        UPDATE_BATCH_TYPE: buildEndpoint('batches/batch-types/:id/update'),
        DELETE_BATCH_TYPE: buildEndpoint('batches/batch-types/:id/delete'),
        ACTIVE_BATCH_TYPE: buildEndpoint('batches/batch-types/:id/active')
    },
    BRAND: {
        GET_BRANDS: buildEndpoint('batches/brands'),
        STORE_BRAND: buildEndpoint('batches/brands/store'),
        EDIT_BRAND: buildEndpoint('batches/brands/:id/edit'),
        CREATE_BRAND: buildEndpoint('batches/brands/create'),
        SHOW_BRAND: buildEndpoint('batches/brands/:id/show'),
        UPDATE_BRAND: buildEndpoint('batches/brands/:id/update'),
        DELETE_BRAND: buildEndpoint('batches/brands/:id/delete'),
        ACTIVE_BRAND: buildEndpoint('batches/brands/:id/active')
    },
    CENTER: {
        GET_CENTERS: buildEndpoint('centers/centers'),
        STORE_CENTER: buildEndpoint('centers/centers/store'),
        EDIT_CENTER: buildEndpoint('centers/centers/:id/edit'),
        CREATE_CENTER: buildEndpoint('centers/centers/create'),
        SHOW_CENTER: buildEndpoint('centers/centers/:id/show'),
        UPDATE_CENTER: buildEndpoint('centers/centers/:id/update'),
        DELETE_CENTER: buildEndpoint('centers/centers/:id/delete'),
        ACTIVE_CENTER: buildEndpoint('centers/centers/:id/active')
    },
    CHECKING_ACCOUNTS: {
        GET_CHECKING_ACCOUNTS: buildEndpoint('banks/checking-accounts'),
        CREATE_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/:transfer_type/create'),
        STORE_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/store'),
        EDIT_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/:id/edit'),
        SHOW_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/:id/show'),
        UPDATE_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/:id/update'),
        DELETE_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/:id/delete'),
        ACTIVE_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/:id/active')
    },
    CLASIFICATION: {
        GET_CLASIFICATIONS: buildEndpoint('accounts/clasifications'),
        STORE_CLASIFICATION: buildEndpoint('accounts/clasifications/store'),
        EDIT_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/edit'),
        CREATE_CLASIFICATION: buildEndpoint('accounts/clasifications/create'),
        SHOW_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/show'),
        UPDATE_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/update'),
        DELETE_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/delete'),
        ACTIVE_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/active')
    },
    RUNNERS: {
        GET_ALL: buildEndpoint('auths/runners/:transportation_order_item_id'),
        ASSIGN_RUNNER: buildEndpoint('auths/assign-runner')
    },
    NOTIFICATIONS: {
        GET_ALL_BY_USER_RANGE: buildEndpoint('notifications/notifications/by-user-range'),
        GET_NOT_VIEWED: buildEndpoint('notifications/notifications/not-viewed'),
        SET_AS_VIEWED: buildEndpoint('notifications/notifications/:id/viewed')
    },
    CLIENTS: {
        GET_CLIENTS: buildEndpoint('clients/'),
        GET_CLIENT: buildEndpoint('clients/:id'),
        CREATE_CLIENT: buildEndpoint('clients/create'),
        STORE_CLIENT: buildEndpoint('clients/store'),
        EDIT_CLIENT: buildEndpoint('clients/:id/edit'),
        SHOW_CLIENT: buildEndpoint('clients/:id/show'),
        UPDATE_CLIENT: buildEndpoint('clients/:id/update'),
        DELETE_CLIENT: buildEndpoint('clients/:id/delete'),
        GET_CLIENTS_BY_SOCIETY: buildEndpoint('clients/:society_id/by-society')
    },
    CARRIERS: {
        GET_CARRIERS: buildEndpoint('carriers/carriers'),
        GET_CARRIER: buildEndpoint('carriers/carriers/:id'),
        CREATE_CARRIER: buildEndpoint('carriers/carriers/create'),
        STORE_CARRIER: buildEndpoint('carriers/carriers/store'),
        EDIT_CARRIER: buildEndpoint('carriers/carriers/:id/edit'),
        SHOW_CARRIER: buildEndpoint('carriers/:id/show'),
        UPDATE_CARRIER: buildEndpoint('carriers/carriers/:id/update'),
        DELETE_CARRIER: buildEndpoint('carriers/carriers/:id/delete')
    },
    TRUCKS: {
        GET_TRUCKS: buildEndpoint('carriers/trucks'),
        GET_TRUCK: buildEndpoint('carriers/trucks/:id'),
        CREATE_TRUCK: buildEndpoint('carriers/trucks/create'),
        STORE_TRUCK: buildEndpoint('carriers/trucks/store'),
        EDIT_TRUCK: buildEndpoint('carriers/trucks/:id/edit'),
        SHOW_TRUCK: buildEndpoint('carriers/trucks/:id/show'),
        UPDATE_TRUCK: buildEndpoint('carriers/trucks/:id/update'),
        DELETE_TRUCK: buildEndpoint('carriers/trucks/:id/delete')
    },
    HOPPERS: {
        GET_HOPPERS: buildEndpoint('carriers/hoppers'),
        GET_HOPPER: buildEndpoint('carriers/hoppers/:id'),
        CREATE_HOPPER: buildEndpoint('carriers/hoppers/create'),
        STORE_HOPPER: buildEndpoint('carriers/hoppers/store'),
        EDIT_HOPPER: buildEndpoint('carriers/hoppers/:id/edit'),
        SHOW_HOPPER: buildEndpoint('carriers/hoppers/:id/show'),
        UPDATE_HOPPER: buildEndpoint('carriers/hoppers/:id/update'),
        DELETE_HOPPER: buildEndpoint('carriers/hoppers/:id/delete')
    },
    INTERNAL_SETTLEMENTS: {
        GET_INTERNAL_SETTLEMENTS: buildEndpoint('banks/internal-settlements'),
        GET_EXCEL_INTERNAL_SETTLEMENTS: buildEndpoint('banks/internal-settlements/excel'),
        SHOW_INTERNAL_SETTLEMENT: buildEndpoint('banks/internal-settlements/:id/show'),
        GET_DATA_TO_FILTER: buildEndpoint('banks/internal-settlements/all-data-filter')
    },
    DAILY_CARRIERS: {
        GET_DAILY_CARRIERS: buildEndpoint('carriers/daily-carriers'),
        GET_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/:id'),
        CREATE_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/create'),
        STORE_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/store'),
        EDIT_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/:id/edit'),
        SHOW_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/:id/show'),
        UPDATE_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/:id/update'),
        DELETE_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/:id/delete'),
    },
    BUSINESS_NAMES: {
        GET_BUSINESS_NAMES: buildEndpoint('business-names/business-names'),
        CREATE_BUSINESS_NAME: buildEndpoint('business-names/business-names/create'),
        STORE_BUSINESS_NAME: buildEndpoint('business-names/business-names/store'),
        EDIT_BUSINESS_NAME: buildEndpoint('business-names/business-names/:id/edit'),
        SHOW_BUSINESS_NAME: buildEndpoint('business-names/business-names/:id/show'),
        UPDATE_BUSINESS_NAME: buildEndpoint('business-names/business-names/:id/update'),
        DELETE_BUSINESS_NAME: buildEndpoint('business-names/business-names/:id/delete'),
        ACTIVE_BUSINESS_NAME: buildEndpoint('business-names/business-names/:id/active'),
        GET_BUSINESS_NAMES_BY_ENTITY: buildEndpoint('business-names/business-names/:entity/by-entity'),
    },
    BUSINESS_NAME_CONTACTS: {
        GET_ALL_BUSINESS_NAME_CONTACTS: buildEndpoint('contacts/contacts'),
        GET_ALL_BUSINESS_NAME_CONTACTS_BY_BUSINESS_NAME: buildEndpoint('contacts/contacts/:business_name_id/by-business-name'),
        GET_ALL_BUSINESS_NAME_CONTACTS_BY_CLIENT: buildEndpoint('contacts/contacts/:client_id/by-client'),
        GET_ALL_BUSINESS_NAME_CONTACTS_BY_PROVIDER: buildEndpoint('contacts/contacts/:provider_id/by-provider'),
        GET_ALL_BUSINESS_NAME_CONTACTS_BY_SOCIETY: buildEndpoint('contacts/contacts/:society_id/by-society'),
        CREATE_BUSINESS_NAME_CONTACT: buildEndpoint('contacts/contacts/create'),
        STORE_BUSINESS_NAME_CONTACT: buildEndpoint('contacts/contacts/store'),
        EDIT_BUSINESS_NAME_CONTACT: buildEndpoint('contacts/contacts/:id/edit'),
        SHOW_BUSINESS_NAME_CONTACT: buildEndpoint('contacts/contacts/:id/show'),
        UPDATE_BUSINESS_NAME_CONTACT: buildEndpoint('contacts/contacts/:id/update'),
        DELETE_BUSINESS_NAME_CONTACT: buildEndpoint('contacts/contacts/:id/delete'),
        ACTIVE_BUSINESS_NAME_CONTACT: buildEndpoint('contacts/contacts/:id/active'),
        GET_BUSINESS_NAME_CONTACT_BY_CLIENT: buildEndpoint('contacts/contacts/:client_id/get-by-client'),
        GET_BUSINESS_NAME_CONTACT_BY_PROVIDER: buildEndpoint('contacts/contacts/:provider_id/get-by-provider'),
    },
    GROUPS: {
        GET_GROUPS: buildEndpoint('auths/groups'),
        STORE_GROUP: buildEndpoint('auths/groups/store'),
        EDIT_GROUP: buildEndpoint('auths/groups/:id/edit'),
        CREATE_GROUP: buildEndpoint('auths/groups/create'),
        SHOW_GROUP: buildEndpoint('auths/groups/:id/show'),
        UPDATE_GROUP: buildEndpoint('auths/groups/:id/update'),
        DELETE_GROUP: buildEndpoint('auths/groups/:id/delete'),
        ACTIVE_GROUP: buildEndpoint('auths/groups/:id/active')
    },
    SOCIETIES: {
        GET_SOCIETIES: buildEndpoint('societies/societies'),
        // GET_SOCIETY: buildEndpoint('societies/:id'),
        STORE_SOCIETY: buildEndpoint('societies/societies/store'),
        EDIT_SOCIETY: buildEndpoint('societies/societies/:id/edit'),
        CREATE_SOCIETY: buildEndpoint('societies/societies/create'),
        SHOW_SOCIETY: buildEndpoint('societies/societies/:id/show'),
        UPDATE_SOCIETY: buildEndpoint('societies/societies/:id/update'),
        DELETE_SOCIETY: buildEndpoint('societies/societies/:id/delete'),
        ACTIVE_SOCIETY: buildEndpoint('societies/societies/:id/active'),
        GET_SOCIETY: buildEndpoint('societies/societies/:id/get'),
        GET_SOCIETY_AND_PROVIDERS: buildEndpoint('societies/societies/:id/get-providers'),
    },
    SOCIETY_CLIENTS: {
        GET_SOCIETY_CLIENTS_BY_SOCIETY: buildEndpoint('societies/society-clients/:society_id/by-society'),
        STORE_SOCIETY_CLIENT: buildEndpoint('societies/society-clients/store'),
        EDIT_SOCIETY_CLIENT: buildEndpoint('societies/society-clients/:id/edit'),
        CREATE_SOCIETY_CLIENT: buildEndpoint('societies/society-clients/create'),
        SHOW_SOCIETY_CLIENT: buildEndpoint('societies/society-clients/:id/show'),
        UPDATE_SOCIETY_CLIENT: buildEndpoint('societies/society-clients/:id/update'),
        DELETE_SOCIETY_CLIENT: buildEndpoint('societies/society-clients/:id/delete'),
        ACTIVE_SOCIETY_CLIENT: buildEndpoint('societies/society-clients/:id/active'),
    },
    SOCIETY_PROVIDERS: {
        GET_SOCIETY_PROVIDERS_BY_SOCIETY: buildEndpoint('societies/society-providers/:society_id/by-society'),
        STORE_SOCIETY_PROVIDER: buildEndpoint('societies/society-providers/store'),
        EDIT_SOCIETY_PROVIDER: buildEndpoint('societies/society-providers/:id/edit'),
        CREATE_SOCIETY_PROVIDER: buildEndpoint('societies/society-providers/create'),
        SHOW_SOCIETY_PROVIDER: buildEndpoint('societies/society-providers/:id/show'),
        UPDATE_SOCIETY_PROVIDER: buildEndpoint('societies/society-providers/:id/update'),
        DELETE_SOCIETY_PROVIDER: buildEndpoint('societies/society-providers/:id/delete'),
        ACTIVE_SOCIETY_PROVIDER: buildEndpoint('societies/society-providers/:id/active'),
    },
    SOCIETIES_SIGNATURES:{
        GET_SOCIETY_SIGNATURES: buildEndpoint('societies/societies-signatures'),
        CREATE_SOCIETY_SIGNATURE: buildEndpoint('societies/societies-signatures/create'),
        STORE_SOCIETY_SIGNATURE: buildEndpoint('societies/societies-signatures/store'),
        EDIT_SOCIETY_SIGNATURE: buildEndpoint('societies/societies-signatures/:id/edit'),
        UPDATE_SOCIETY_SGINATURE: buildEndpoint('societies/societies-signatures/:id/update'),
        DELETE_SOCIETY_SIGNATURE: buildEndpoint('societies/societies-signatures/:id/delete'),
    },
    PURCHASE_ORDER : {
        GET_PURCHASE_ORDERS: buildEndpoint('purchase-orders/purchase-orders'),
        CREATE_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-orders/create'),
        STORE_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-orders/store'),
        EDIT_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-orders/:id/edit'),
        SHOW_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-orders/:id/show'),
        UPDATE_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-orders/:id/update'),
        DELETE_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-orders/:id/delete'),
        HAS_ALL_FINISHED_STATUSES: buildEndpoint(
            'purchase-orders/purchase-orders/:id/:purchase_order_status/has-finished'
        ),
        CHANGE_STATUS_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-orders/:id/change-status'),
        CHANGE_STATUS_TO_CONFIRMED_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-orders/:id/change-status-to-confirmed'),
        CHANGE_STATUS_TO_ANNULLED_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-orders/:id/change-status-to-annulled'),
        CHANGE_STATUS_TO_PENDING_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-orders/:id/change-status-to-pending'),
        OVERRIDE_CONFIRMED_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-orders/:id/override-confirmed'),
        HAS_ALL_CONFIRMED_BY_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-orders/:purchase_order_id/has-all-confirmed'),
        GET_PURCHASE_ORDERS_BY_SOCIETY_AND_PROVIDER: buildEndpoint('purchase-orders/purchase-orders/:society_id/:provider_id/:id/get-purchase-orders'),
    },
    PURCHASE_ORDER_DETAIL : {
        GET_PURCHASE_ORDER_DETAILS_BY_PURCHASE_ORDER: buildEndpoint('purchase-orders/purchase-order-details/:purchase_order_id/by-purchase-order'),
        GET_PURCHASE_ORDER_DETAILS_FINISHED: buildEndpoint('purchase-orders/purchase-order-details/:dispatch_program_id/finished'),
        STORE_PURCHASE_ORDER_DETAIL: buildEndpoint('purchase-orders/purchase-order-details/store'),
        CREATE_PURCHASE_ORDER_DETAIL: buildEndpoint('purchase-orders/purchase-order-details/:item_type/create'),
        EDIT_PURCHASE_ORDER_DETAIL: buildEndpoint('purchase-orders/purchase-order-details/:id/edit'),
        SHOW_PURCHASE_ORDER_DETAIL: buildEndpoint('purchase-orders/purchase-order-details/:id/show'),
        GET_PURCHASE_ORDER_DETAIL: buildEndpoint('purchase-orders/purchase-order-details/:id/:service_manifest_detail_id/get'),
        UPDATE_PURCHASE_ORDER_DETAIL: buildEndpoint('purchase-orders/purchase-order-details/:id/update'),
        DELETE_PURCHASE_ORDER_DETAIL: buildEndpoint('purchase-orders/purchase-order-details/:id/delete'),
    },
    SERVICE: {
        GET_SERVICES: buildEndpoint('services/services'),
        STORE_SERVICE: buildEndpoint('services/services/store'),
        EDIT_SERVICE: buildEndpoint('services/services/:id/edit'),
        CREATE_SERVICE: buildEndpoint('services/services/create'),
        SHOW_SERVICE: buildEndpoint('services/services/:id/show'),
        UPDATE_SERVICE: buildEndpoint('services/services/:id/update'),
        DELETE_SERVICE: buildEndpoint('services/services/:id/delete'),
        ACTIVE_SERVICE: buildEndpoint('services/services/:id/active'),
        GET_SERVICE: buildEndpoint('services/services/:id/get'),
        GET_SERVICE_WITH_PURCHASE_ORDER: buildEndpoint('services/services/:id/:purchase_order_id/with-purchase-order'),
    },
    SERVICE_MANIFEST: {
        GET_SERVICE_MANIFESTS: buildEndpoint('service-manifests/service-manifests'),
        CREATE_SERVICE_MANIFEST: buildEndpoint('service-manifests/service-manifests/create'),
        STORE_SERVICE_MANIFEST: buildEndpoint('service-manifests/service-manifests/store'),
        EDIT_SERVICE_MANIFEST: buildEndpoint('service-manifests/service-manifests/:id/edit'),
        SHOW_SERVICE_MANIFEST: buildEndpoint('service-manifests/service-manifests/:id/show'),
        UPDATE_SERVICE_MANIFEST: buildEndpoint('service-manifests/service-manifests/:id/update'),
        DELETE_SERVICE_MANIFEST: buildEndpoint('service-manifests/service-manifests/:id/delete'),
        HAS_ALL_FINISHED_STATUSES: buildEndpoint(
            'service-manifests/service-manifests/:id/:service_manifest_status/has-finished'
        ),
        CHANGE_STATUS_SERVICE_MANIFEST: buildEndpoint(
            'service-manifests/service-manifests/:id/change-status'
        ),
        DELETE_SERVICE_MANIFEST_BY_DISPATCH_PROGRAM: buildEndpoint('service-manifests/service-manifests/delete-on-dp'),
        STORE_SERVICE_MANIFEST_ON_DISPATCH_PROGRAM: buildEndpoint('service-manifests/service-manifests/store-on-dp'),
        GO_SERVICE_MANIFEST: buildEndpoint('service-manifests/service-manifests/:provider_id/:dispatch_program_id/go-service-manifest'),
    },
    SERVICE_MANIFEST_DOCUMENT: {
        GET_SERVICE_MANIFEST_DOCUMENTS_BY_SERVICE_MANIFEST: buildEndpoint(
            'service-manifests/service-manifest-documents/:service_manifest_id/by-service-manifest'
        ),
        CREATE_SERVICE_MANIFEST_DOCUMENT: buildEndpoint(
            'service-manifests/service-manifest-documents/create'
        ),
        STORE_SERVICE_MANIFEST_DOCUMENT: buildEndpoint(
            'service-manifests/service-manifest-documents/store'
        ),
        EDIT_SERVICE_MANIFEST_DOCUMENT: buildEndpoint(
            'service-manifests/service-manifest-documents/:id/edit'
        ),
        SHOW_SERVICE_MANIFEST_DOCUMENT: buildEndpoint(
            'service-manifests/service-manifest-documents/:id/show'
        ),
        UPDATE_SERVICE_MANIFEST_DOCUMENT: buildEndpoint(
            'service-manifests/service-manifest-documents/:id/update'
        ),
        DELETE_SERVICE_MANIFEST_DOCUMENT: buildEndpoint(
            'service-manifests/service-manifest-documents/:id/delete'
        )
    },
    SERVICE_MANIFEST_DETAIL: {
        GET_SERVICE_MANIFEST_DETAILS_BY_SERVICE_MANIFEST: buildEndpoint(
            'service-manifests/service-manifest-details/:service_manifest_id/by-service-manifest'
        ),
        STORE_SERVICE_MANIFEST_DETAIL: buildEndpoint(
            'service-manifests/service-manifest-details/store'
        ),
        CREATE_SERVICE_MANIFEST_DETAIL: buildEndpoint(
            'service-manifests/service-manifest-details/:service_manifest_id/create'
        ),
        EDIT_SERVICE_MANIFEST_DETAIL: buildEndpoint(
            'service-manifests/service-manifest-details/:id/edit'
        ),
        SHOW_SERVICE_MANIFEST_DETAIL: buildEndpoint(
            'service-manifests/service-manifest-details/:id/show'
        ),
        UPDATE_SERVICE_MANIFEST_DETAIL: buildEndpoint(
            'service-manifests/service-manifest-details/:id/update'
        ),
        DELETE_SERVICE_MANIFEST_DETAIL: buildEndpoint(
            'service-manifests/service-manifest-details/:id/delete'
        ),
        HAS_ALL_FINISHED_STATUSES: buildEndpoint(
            'service-manifests/service-manifest-details/:id/:service_manifest_detail_status/has-finished'
        ),
        CHANGE_STATUS_SERVICE_MANIFEST_DETAIL: buildEndpoint(
            'service-manifests/service-manifest-details/:id/change-status'
        )
    },
    SERVICE_ACCOUNT: {
        STORE_SERVICE_ACCOUNT: buildEndpoint('service-manifests/service-accounts/store'),
        EDIT_SERVICE_ACCOUNT: buildEndpoint('service-manifests/service-accounts/:id/edit'),
        SHOW_SERVICE_ACCOUNT: buildEndpoint('service-manifests/service-accounts/:id/show'),
        UPDATE_SERVICE_ACCOUNT: buildEndpoint('service-manifests/service-accounts/:id/update'),
        DELETE_SERVICE_ACCOUNT: buildEndpoint('service-manifests/service-accounts/:id/delete')
    },
    TAX: {
        GET_TAXES: buildEndpoint('taxes/taxes'),
        STORE_TAX: buildEndpoint('taxes/taxes/store'),
        EDIT_TAX: buildEndpoint('taxes/taxes/:id/edit'),
        CREATE_TAX: buildEndpoint('taxes/taxes/create'),
        SHOW_TAX: buildEndpoint('taxes/taxes/:id/show'),
        UPDATE_TAX: buildEndpoint('taxes/taxes/:id/update'),
        DELETE_TAX: buildEndpoint('taxes/taxes/:id/delete'),
        ACTIVE_TAX: buildEndpoint('taxes/taxes/:id/active'),
    },
    DEPOT: {
        GET_DEPOTS: buildEndpoint('centers/depots'),
        STORE_DEPOT: buildEndpoint('centers/depots/store'),
        EDIT_DEPOT: buildEndpoint('centers/depots/:id/edit'),
        CREATE_DEPOT: buildEndpoint('centers/depots/create'),
        SHOW_DEPOT: buildEndpoint('centers/depots/:id/show'),
        UPDATE_DEPOT: buildEndpoint('centers/depots/:id/update'),
        DELETE_DEPOT: buildEndpoint('centers/depots/:id/delete'),
        ACTIVE_DEPOT: buildEndpoint('centers/depots/:id/active')
    },
    INCOTERM: {
        GET_INCOTERMS: buildEndpoint('purchase-orders/incoterms'),
        STORE_INCOTERM: buildEndpoint('purchase-orders/incoterms/store'),
        EDIT_INCOTERM: buildEndpoint('purchase-orders/incoterms/:id/edit'),
        CREATE_INCOTERM: buildEndpoint('purchase-orders/incoterms/create'),
        SHOW_INCOTERM: buildEndpoint('purchase-orders/incoterms/:id/show'),
        UPDATE_INCOTERM: buildEndpoint('purchase-orders/incoterms/:id/update'),
        DELETE_INCOTERM: buildEndpoint('purchase-orders/incoterms/:id/delete'),
        ACTIVE_INCOTERM: buildEndpoint('purchase-orders/incoterms/:id/active'),
    },
    PAYMENTS: {
        GET_PAYMENT_METHODS: buildEndpoint('payment-methods/'),
        GET_PAYMENT_METHOD: buildEndpoint('payment-methods/:id'),
        GET_PAYMENT_CONDITIONS: buildEndpoint('payment-conditions/'),
        GET_PAYMENT_CONDITION: buildEndpoint('payment-conditions/:id')
    },
    PAYMENT_METHOD: {
        GET_PAYMENT_METHODS: buildEndpoint('payments/payment-methods/'),
        STORE_PAYMENT_METHOD: buildEndpoint('payments/payment-methods/store'),
        EDIT_PAYMENT_METHOD: buildEndpoint('payments/payment-methods/:id/edit'),
        UPDATE_PAYMENT_METHOD: buildEndpoint('payments/payment-methods/:id/update'),
        DELETE_PAYMENT_METHOD: buildEndpoint('payments/payment-methods/:id/delete'),
        ACTIVE_PAYMENT_METHOD: buildEndpoint('payments/payment-methods/:id/active'),
    },
    CURRENCIES: {
        GET_CURRENCIES: buildEndpoint('currencies/currencies'),
        CREATE_CURRENCY: buildEndpoint('currencies/currencies/create'),
        STORE_CURRENCY: buildEndpoint('currencies/currencies/store'),
        EDIT_CURRENCY: buildEndpoint('currencies/currencies/:id/edit'),
        SHOW_CURRENCY: buildEndpoint('currencies/currencies/:id/show'),
        UPDATE_CURRENCY: buildEndpoint('currencies/currencies/:id/update'),
        DELETE_CURRENCY: buildEndpoint('currencies/currencies/:id/delete'),
        ACTIVE_CURRENCY: buildEndpoint('currencies/currencies/:id/active')
    },
    PRODUCTS: {
        GET_PRODUCTS: buildEndpoint('products/products'),
        STORE_PRODUCT: buildEndpoint('products/products/store'),
        EDIT_PRODUCT: buildEndpoint('products/products/:id/edit'),
        CREATE_PRODUCT: buildEndpoint('products/products/create'),
        SHOW_PRODUCT: buildEndpoint('products/products/:id/show'),
        UPDATE_PRODUCT: buildEndpoint('products/products/:id/update'),
        DELETE_PRODUCT: buildEndpoint('products/products/:id/delete'),
        ACTIVE_PRODUCT: buildEndpoint('products/products/:id/active'),
        GET_PRODUCT: buildEndpoint('products/products/:id/get'),
        GET_PRODUCT_WITH_PURCHASE_ORDER: buildEndpoint('products/products/:id/:purchase_order_id/with-purchase-order')
    },
    PROVIDERS: {
        GET_PROVIDERS: buildEndpoint('providers/providers'),
        // GET_PROVIDER: buildEndpoint('providers/providers/:id'),
        CREATE_PROVIDER: buildEndpoint('providers/providers/create'),
        STORE_PROVIDER: buildEndpoint('providers/providers/store'),
        EDIT_PROVIDER: buildEndpoint('providers/providers/:id/edit'),
        SHOW_PROVIDER: buildEndpoint('providers/:id/show'),
        UPDATE_PROVIDER: buildEndpoint('providers/providers/:id/update'),
        DELETE_PROVIDER: buildEndpoint('providers/providers/:id/delete'),
        ACTIVE_PROVIDER: buildEndpoint('providers/providers/:id/active'),
        GET_PROVIDER: buildEndpoint('providers/providers/:id/get'),
        GET_DAILY_CARRIER_PROVIDERS_ON_DISPATCH_PROGRAM: buildEndpoint('providers/providers/:dispatch_program_id/providers-on-dp'),
        GET_DAILY_CARRIERS_PROVIDERS_WITH_SERVICE_MANIFESTS: buildEndpoint('providers/providers/:dispatch_program_id/with-service-manifest'),
    },
    QUOTES: {
        // quote requests
        GET_QUOTES: buildEndpoint('quotes/quotes'),
        GET_EXCEL_QUOTES: buildEndpoint('quotes/quotes/excel'),
        GET_QUOTE: buildEndpoint('quotes/quotes/:id'),
        CREATE_QUOTE: buildEndpoint('quotes/quotes/create'),
        STORE_QUOTE: buildEndpoint('quotes/quotes/store'),
        EDIT_QUOTE: buildEndpoint('quotes/quotes/:id/edit'),
        UPDATE_QUOTE: buildEndpoint('quotes/quotes/:id/update'),
        SHOW_QUOTE: buildEndpoint('quotes/quotes/:id/show'),
        DELETE_QUOTE: buildEndpoint('quotes/quotes/:id/delete'),
        REPEAT_QUOTE: buildEndpoint('quotes/quotes/repeat'),
        DOCUMENT_QUOTE: buildEndpoint('quotes/quotes/:id/document'),
        CHANGE_CHECK_ROUTES_DOCUMENT_QUOTE: buildEndpoint('quotes/quotes/:id/document-routes'),
        CONFIRM_QUOTE: buildEndpoint('quotes/quotes/:id/confirm'),
        REJECT_QUOTE: buildEndpoint('quotes/quotes/:id/reject'),

        CLIENT_REVIEW_QUOTE: buildEndpoint('quotes/quotes/:id/client-review'),
        OPERATION_REVIEW_QUOTE: buildEndpoint('quotes/quotes/:id/operation-review'),

        PREPARATION_QUOTE: buildEndpoint('quotes/quotes/:id/preparation'),

        // quote details
        GET_TRANSPORTATION_QUOTE_DETAILS_BY_REQUEST: buildEndpoint(
            'quotes/transportation-quote-details/:id/by-request'
        ),
        GET_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint('quotes/transportation-quote-details/:id'),
        CREATE_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/create'
        ),
        STORE_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/store'
        ),
        GET_QUOTE_ROUTE_SEGMENTS_TO_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/:quote_id/:route_segment_id/get-quote-rs'
        ),
        EDIT_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/:id/edit'
        ),
        SHOW_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/:id/show'
        ),
        UPDATE_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/:id/update'
        ),
        DELETE_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/:id/delete'
        )
    },
    PLACES: {
        GET_PLACES: buildEndpoint('places/places'),
        GET_PLACE: buildEndpoint('places/places/:id'),
        CREATE_PLACE: buildEndpoint('places/places/create'),
        STORE_PLACE: buildEndpoint('places/places/store'),
        EDIT_PLACE: buildEndpoint('places/places/:id/edit'),
        SHOW_PLACE: buildEndpoint('places/:id/show'),
        UPDATE_PLACE: buildEndpoint('places/places/:id/update'),
        DELETE_PLACE: buildEndpoint('places/places/:id/delete')
    },
    PROVIDER_TYPES: {
        GET_PROVIDER_TYPES: buildEndpoint('providers/provider_types'),
        GET_PROVIDER_TYPE: buildEndpoint('providers/provider_types/:id'),
        CREATE_PROVIDER_TYPE: buildEndpoint('providers/provider_types/create'),
        STORE_PROVIDER_TYPE: buildEndpoint('providers/provider_types/store'),
        EDIT_PROVIDER_TYPE: buildEndpoint('providers/provider_types/:id/edit'),
        SHOW_PROVIDER_TYPE: buildEndpoint('providers/:id/show'),
        UPDATE_PROVIDER_TYPE: buildEndpoint('providers/provider_types/:id/update'),
        DELETE_PROVIDER_TYPE: buildEndpoint('providers/provider_types/:id/delete')
    },
    PAYMENT_STATUS: {
        GET_PAYMENT_STATUSES: buildEndpoint('payment-statuses/payment-statuses'),
        GENERATE_PAYMENT_STATUS: buildEndpoint('payment-statuses/payment-statuses/generate'),
        GET_PAYMENT_DOCUMENT: buildEndpoint('payment-statuses/payment-statuses/:id/get'),
        CAN_CHANGE_PAYMENT_STATUS: buildEndpoint('payment-statuses/payment-statuses/:id/:status/can-change-status'),
        CHANGE_STATUS_PAYMENT_STATUS: buildEndpoint('payment-statuses/payment-statuses/:id/change-status'),
        EDIT_PAYMENT_STATUS: buildEndpoint('payment-statuses/payment-statuses/:id/edit'),
        // GET_PAYMENT_STATUS: buildEndpoint('payment-statuses/payment-statuses/:id'),
        DELETE_PAYMENT_STATUS: buildEndpoint('payment-statuses/payment-statuses/:id/delete'),
        // GENERATE_PAYMENT_STATUS: buildEndpoint('dispatch-programs/payment-status/generate'),
        // CREATE_PAYMENT_STATUS: buildEndpoint('dispatch-programs/payment-status/create'),
        // STORE_PAYMENT_STATUS: buildEndpoint('dispatch-programs/payment-status/store'),
        // SHOW_PAYMENT_STATUS: buildEndpoint('dispatch-programs/payment-status/::id/show'),
        UPDATE_PAYMENT_STATUS: buildEndpoint('payment-statuses/payment-statuses/:id/update'),
    },
    PAYMENT_STATUS_DETAIL: {
        GET_PAYMENT_STATUS_DETAILS_BY_PAYMENT_STATUS: buildEndpoint('payment-statuses/payment-status-details/:payment_status_id/by-payment-status'),
        
    },
    REGIONS: {
        GET_REGIONS_BY_COUNTRY: buildEndpoint('communes/regions/:country_id/get-by-country')
    },
    COMMUNES: {
        GET_COMMUNES_BY_REGION: buildEndpoint('communes/communes/:region_id/get-by-region')
    },
    TRANSPORTATION_ORDERS: {
        GET_EXCEL_TRANSPORTATION_ORDERS: buildEndpoint(
            'transportation-orders/transportation-orders/excel'
        ),
        GET_TRANSPORTATION_ORDERS: buildEndpoint('transportation-orders/transportation-orders'),
        CREATE_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/create'
        ),
        STORE_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/store'
        ),
        SHOW_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/:id/show'
        ),
        EDIT_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/:id/edit'
        ),
        CAN_EDIT_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/can-edit'
        ),
        UPDATE_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/:id/update'
        ),
        UPDATE_TRANSPORTATION_ORDER_RATE_RUNNER: buildEndpoint(
            'transportation-orders/transportation-orders/:id/update-rates'
        ),
        DELETE_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/:id/delete'
        ),
        SHOW_QUOTE_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/:id/show-quote'
        ),
        CAN_CHANGE_TRANSPORTATION_ORDER: buildEndpoint('transportation-orders/transportation-orders/:id/:status/can-change-status'),
        CHANGE_STATUS_TRANSPORTATION_ORDER: buildEndpoint('transportation-orders/transportation-orders/:id/change-status'),
        CHANGE_STATUS_TO_ACTIVE_TRANSPORTATION_ORDER: buildEndpoint('transportation-orders/transportation-orders/:id/change-to-active-status'),
        CHANGE_STATUS_TO_FINISH_TRANSPORTATION_ORDER: buildEndpoint('transportation-orders/transportation-orders/:id/change-to-finish-status'),

        GET_TRANSPORTATION_ORDER_ITEMS_BY_ORDER: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/by-order'
        ),
        GET_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id'
        ),
        CREATE_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/create'
        ),
        STORE_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/store'
        ),
        EDIT_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/edit'
        ),
        SHOW_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/show'
        ),
        UPDATE_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/update'
        ),
        DELETE_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/delete'
        ),
        HAS_ALL_FINISHED_TRANSPORTATION_ORDER_ITEMS: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/:status_detail_toi/has-all-finished-transportation-order-items'
        ),
        CHANGE_STATUS_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/change-status'
        ),
        CHANGE_TO_PREPARATION_STATUS_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/change-to-preparation-status'
        ),
        CHANGE_TO_DISPATCH_STATUS_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/change-to-dispatch-status'
        ),
        CHANGE_TO_FINISH_STATUS_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/change-to-finish-status'
        ),
        SPLIT_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/split'
        ),
        GET_RATES_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/edit-rates'
        ),
        UPDATE_RATES_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/update-rates'
        ),

        GET_TRANSPORTATION_SUB_ORDER_ITEMS_BY_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id/by-item'
        ),
        GET_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id'
        ),
        CREATE_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/create'
        ),

        STORE_TRANSPORTATION_SUB_ORDER_ITEMS: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/store'
        ),
        EDIT_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id/edit'
        ),
        SHOW_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id/show'
        ),
        UPDATE_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id/update'
        ),
        DELETE_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id/delete'
        ),
        DISPATCH_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id/dispatch'
        )
    },
    DISPATCH_PROGRAMS: {
        CAN_CHANGE_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/:id/:status/can-change-status'),
        CHANGE_STATUS_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/:id/change-status'),
        CHANGE_TO_DISPATCH_STATUS_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/:id/change-status-to-dispatch'),
        CHANGE_TO_FINISH_STATUS_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/:id/change-status-to-finish'),
        GET_EXCEL_DISPATCH_PROGRAMS: buildEndpoint('dispatch-programs/dispatch-programs/excel'),
        GET_DISPATCH_PROGRAMS: buildEndpoint('dispatch-programs/dispatch-programs'),
        LOAD_BULK: buildEndpoint('dispatch-programs/dispatch-programs/load-bulk'),
        HAS_ALL_FINISHED_DISPATCH_PROGRAM_ITEMS: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/:status_detail_dpi/has-all-finished-dispatch-program-items'
        ),
        HAS_ALL_FINISHED_DISPATCH_PROGRAM_ROWS: buildEndpoint(
            'dispatch-programs/dispatch-program-items/has-all-finished-dispatch-program-rows'
        ),
        CREATE_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/create'),
        STORE_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/store'),
        SHOW_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/:id/show'),
        EDIT_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/:id/edit'),
        UPDATE_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/:id/update'),
        DELETE_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/:id/delete'),
        SHOW_TRANSPORTATION_ORDER_DISPATCH_PROGRAM: buildEndpoint(
            'dispatch-programs/dispatch-programs/:id/show-transportation-order'
        ),
        CHANGE_STATUSES_ROWS_TO_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/change-rows-status'
        ),
        MASIVE_DELETE_ROWS_DISPATCH_PROGRAM_ITEMS: buildEndpoint(
            'dispatch-programs/dispatch-program-items/masive-rows-delete'
        ),

        GET_TRANSPORTATION_SUB_ORDER_ITEMS_DISPATCH_PROGRAM: buildEndpoint(
            'dispatch-programs/dispatch-programs/:id/transportation-sub-order-items'
        ),

        GET_DISPATCH_PROGRAM_ITEMS_BY_DISPATCH: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/by-dispatch'
        ),

        GET_DISPATCH_PROGRAM_ITEMS_BY_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/by-transportation-sub-order-item'
        ),

        GET_EXCEL_DISPATCH_PROGRAM_ITEMS_BY_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'dispatch-programs/excel-dispatch-program-items/:id/by-transportation-sub-order-item'
        ),
        SPLIT_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-programs/:id/split-transportation-sub-order-item'
        ),

        STORE_SPLIT_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-programs/store-split-transportation-sub-order-item'
        ),

        // DISPATCH PROGRAM ITEMS

        GET_DISPATCH_PROGRAM_ITEM: buildEndpoint('dispatch-programs/dispatch-program-items/:id'),
        CREATE_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/create'
        ),
        STORE_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/store'
        ),
        EDIT_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/edit'
        ),
        SHOW_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/show'
        ),
        UPDATE_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/update'
        ),
        DELETE_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/delete'
        ),

        PREPARATION_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/preparation'
        ),
        PREPARATION_DISPATCH_PROGRAM_ROWS: buildEndpoint(
            'dispatch-programs/dispatch-program-items/preparation/rows'
        ),
        PLANNING_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/planification'
        ),
        PLANNING_DISPATCH_PROGRAM_ROWS: buildEndpoint(
            'dispatch-programs/dispatch-program-items/planification/rows'
        ),
        DISPATCH_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/dispatch'
        ),
        DISPATCH_DISPATCH_PROGRAM_ROWS: buildEndpoint(
            'dispatch-programs/dispatch-program-items/dispatch/rows'
        ),
        FINISH_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/finish'
        ),
        FINISH_DISPATCH_PROGRAM_ROWS: buildEndpoint(
            'dispatch-programs/dispatch-program-items/finish/rows'
        ),
        GET_DISPATCH_PROGRAM_ITEM_DAILY_CARRIERS_AVAILABLE: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/daily-carriers-available'
        ),
        GET_DISPATCH_PROGRAM_ITEM_GUIDE: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/guide'
        ),

        SYNC_DISPATCH_PROGRAM_DAILY_CARRIERS: buildEndpoint(
            'dispatch-programs/dispatch-program-daily-carriers/store'
        )
    },
    DISPATCH_PROGRAM_DOCUMENTS: {
        GET_DISPATCH_PROGRAM_DOCUMENTS: buildEndpoint(
            'dispatch-programs/dispatch-program-documents/:dispatch_program_id'
        ),
        UPDATE_DISPATCH_PROGRAM_DOCUMENTS: buildEndpoint(
            'dispatch-programs/dispatch-program-documents/:dispatch_program_id/update'
        )
    },
    GUIDE: {
        GET_GUIDES: buildEndpoint(
            'dispatch-programs/guides'
        )
    },
    ITEM_CATEGORY: {
        GET_ITEM_CATEGORIES: buildEndpoint('products/item-categories'),
        STORE_ITEM_CATEGORY: buildEndpoint('products/item-categories/store'),
        EDIT_ITEM_CATEGORY: buildEndpoint('products/item-categories/:id/edit'),
        CREATE_ITEM_CATEGORY: buildEndpoint('products/item-categories/create'),
        SHOW_ITEM_CATEGORY: buildEndpoint('products/item-categories/:id/show'),
        UPDATE_ITEM_CATEGORY: buildEndpoint('products/item-categories/:id/update'),
        DELETE_ITEM_CATEGORY: buildEndpoint('products/item-categories/:id/delete'),
        ACTIVE_ITEM_CATEGORY: buildEndpoint('products/item-categories/:id/active'),
    },
    ORIGIN_AREAS: {
        GET_ORIGIN_AREAS: buildEndpoint('products/origin-areas'),
        STORE_ORIGIN_AREA: buildEndpoint('products/origin-areas/store'),
        CREATE_ORIGIN_AREA: buildEndpoint('products/origin-areas/create'),
        EDIT_ORIGIN_AREA: buildEndpoint('products/origin-areas/:id/edit'),
        SHOW_ORIGIN_AREA: buildEndpoint('products/origin-areas/:id/show'),
        UPDATE_ORIGIN_AREA: buildEndpoint('products/origin-areas/:id/update'),
        DELETE_ORIGIN_AREA: buildEndpoint('products/origin-areas/:id/delete'),
        ACTIVE_ORIGIN_AREA: buildEndpoint('products/origin-areas/:id/active')
    },
    PACKING_FORMS: {
        GET_PACKING_FORMS: buildEndpoint('products/packing-forms'),
        STORE_PACKING_FORM: buildEndpoint('products/packing-forms/store'),
        CREATE_PACKING_FORM: buildEndpoint('products/packing-forms/create'),
        EDIT_PACKING_FORM: buildEndpoint('products/packing-forms/:id/edit'),
        SHOW_PACKING_FORM: buildEndpoint('products/packing-forms/:id/show'),
        UPDATE_PACKING_FORM: buildEndpoint('products/packing-forms/:id/update'),
        DELETE_PACKING_FORM: buildEndpoint('products/packing-forms/:id/delete'),
        ACTIVE_PACKING_FORM: buildEndpoint('products/packing-forms/:id/active')
    },
    SECTOR: {
        GET_SECTORS: buildEndpoint('products/sectors'),
        STORE_SECTOR: buildEndpoint('products/sectors/store'),
        EDIT_SECTOR: buildEndpoint('products/sectors/:id/edit'),
        CREATE_SECTOR: buildEndpoint('products/sectors/create'),
        SHOW_SECTOR: buildEndpoint('products/sectors/:id/show'),
        UPDATE_SECTOR: buildEndpoint('products/sectors/:id/update'),
        DELETE_SECTOR: buildEndpoint('products/sectors/:id/delete'),
        ACTIVE_SECTOR: buildEndpoint('products/sectors/:id/active')
    },
    UNITS: {
        GET_UNITS: buildEndpoint('products/units'),
        STORE_UNIT: buildEndpoint('products/units/store'),
        CREATE_UNIT: buildEndpoint('products/units/create'),
        EDIT_UNIT: buildEndpoint('products/units/:id/edit'),
        SHOW_UNIT: buildEndpoint('products/units/:id/show'),
        UPDATE_UNIT: buildEndpoint('products/units/:id/update'),
        DELETE_UNIT: buildEndpoint('products/units/:id/delete'),
        ACTIVE_UNIT: buildEndpoint('products/units/:id/active')
    },
    UNIT_CONVERSIONS: {
        GET_UNIT_CONVERSIONS: buildEndpoint('unit-conversions/unit-conversions'),
        STORE_UNIT_CONVERSION: buildEndpoint('unit-conversions/unit-conversions/store'),
        CREATE_UNIT_CONVERSION: buildEndpoint('unit-conversions/unit-conversions/create'),
        EDIT_UNIT_CONVERSION: buildEndpoint('unit-conversions/unit-conversions/:id/edit'),
        SHOW_UNIT_CONVERSION: buildEndpoint('unit-conversions/unit-conversions/:id/show'),
        UPDATE_UNIT_CONVERSION: buildEndpoint('unit-conversions/unit-conversions/:id/update'),
        DELETE_UNIT_CONVERSION: buildEndpoint('unit-conversions/unit-conversions/:id/delete'),
    },
    FOREIGN_EXCHANGE: {
        GET_FOREIGN_EXCHANGE: buildEndpoint('currencies/foreign-exchanges')
    },
    SIDEBAR_ITEMS: {
        GET_SIDEBAR_ITEMS: buildEndpoint('sidebaritems/sidebaritems'),
        SEARCH_SIDEBAR_ITEMS: buildEndpoint('sidebaritems/sidebaritems/search')


    },
    COST_CENTER: {
        GET_COST_CENTERS: buildEndpoint('cost-centers/cost-centers'),
        CREATE_COST_CENTER: buildEndpoint('cost-centers/cost-centers/create'),
        STORE_COST_CENTER: buildEndpoint('cost-centers/cost-centers/store'),
        EDIT_COST_CENTER: buildEndpoint('cost-centers/cost-centers/:id/edit'),
        SHOW_COST_CENTER: buildEndpoint('cost-centers/cost-centers/:id/show'),
        UPDATE_COST_CENTER: buildEndpoint('cost-centers/cost-centers/:id/update'),
        DELETE_COST_CENTER: buildEndpoint('cost-centers/cost-centers/:id/delete'),
        ACTIVE_COST_CENTER: buildEndpoint('cost-centers/cost-centers/:id/active')
    },
    ROUTE_SEGMENTS: {
        GET_ROUTE_SEGMENTS: buildEndpoint('route-segments/route-segments'),
        CREATE_ROUTE_SEGMENTS: buildEndpoint('route-segments/route-segments/create'),
        STORE_ROUTE_SEGMENT: buildEndpoint('route-segments/route-segments/store'),
        EDIT_ROUTE_SEGMENT: buildEndpoint('route-segments/route-segments/:id/edit'),
        UPDATE_ROUTE_SEGMENT: buildEndpoint('route-segments/route-segments/:id/update'),
        SHOW_RS_TO_QUOTE_ROUTE_SEGMENT: buildEndpoint(
            'route-segments/route-segments/:id/show-route-segment'
        ),
        SHOW_RS_TO_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'route-segments/route-segments/:id/show-route-segment-tqd'
        ),
        DELETE_ROUTE_SEGMENT: buildEndpoint('route-segments/route-segments/:id/delete'),

        GET_QUOTE_ROUTE_SEGMENTS: buildEndpoint('route-segments/quote-route-segments/:id/all'),
        CREATE_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/create'),
        STORE_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/store'),
        EDIT_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/:id/edit'),
        UPDATE_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/:id/update'),
        DELETE_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/:id/delete'),
        SHOW_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/:id/show'),
        GET_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/:id/get')
    },
    CLASIFICATIONS: {
        GET_CLASIFICATIONS: buildEndpoint('accounts/clasifications'),
        CREATE_CLASIFICATIONS: buildEndpoint('accounts/clasifications/create'),
        STORE_CLASIFICATION: buildEndpoint('accounts/clasifications/store'),
        EDIT_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/edit'),
        UPDATE_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/update'),
        DELETE_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/delete'),
        ACTIVE_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/active')
    },
    CLIENT_CONTACT: {
        GET_CLIENT_CONTACTS_BY_CLIENT: buildEndpoint('contacts/client-contacts/:client_id/by-client'),
        CREATE_CLIENT_CONTACT: buildEndpoint('contacts/client-contacts/create'),
        STORE_CLIENT_CONTACT: buildEndpoint('contacts/client-contacts/store'),
        EDIT_CLIENT_CONTACT: buildEndpoint('contacts/client-contacts/:id/edit'),
        SHOW_CLIENT_CONTACT: buildEndpoint('contacts/client-contacts/:id/show'),
        UPDATE_CLIENT_CONTACT: buildEndpoint('contacts/client-contacts/:id/update'),
        DELETE_CLIENT_CONTACT: buildEndpoint('contacts/client-contacts/:id/delete'),
        ACTIVE_CLIENT_CONTACT: buildEndpoint('contacts/client-contacts/:id/active'),
        GET_CLIENT_CONTACT_BY_CLIENT: buildEndpoint('contacts/client-contacts/:client_id/get-by-client'),
    },
    BANK_ACCOUNT_CLIENT: {
        GET_BANK_ACCOUNT_CLIENT_BY_CLIENT: buildEndpoint('banks/bank-accounts-client/:client_id/by-client'),
        CREATE_BANK_ACCOUNT_CLIENT: buildEndpoint('banks/bank-accounts-client/create'),
        STORE_BANK_ACCOUNT_CLIENT: buildEndpoint('banks/bank-accounts-client/store'),
        EDIT_BANK_ACCOUNT_CLIENT: buildEndpoint('banks/bank-accounts-client/:id/edit'),
        UPDATE_BANK_ACCOUNT_CLIENT: buildEndpoint('banks/bank-accounts-client/:id/update'),
        DELETE_BANK_ACCOUNT_CLIENT: buildEndpoint('banks/bank-accounts-client/:id/delete'),
        ACTIVE_BANK_ACCOUNT_CLIENT: buildEndpoint('banks/bank-accounts-client/:id/active'),
    },
    BANK_ACCOUNT_PROVIDER: {
        GET_BANK_ACCOUNT_PROVIDER_BY_PROVIDER: buildEndpoint('banks/bank-accounts-provider/:provider_id/by-provider'),
        CREATE_BANK_ACCOUNT_PROVIDER: buildEndpoint('banks/bank-accounts-provider/create'),
        STORE_BANK_ACCOUNT_PROVIDER: buildEndpoint('banks/bank-accounts-provider/store'),
        EDIT_BANK_ACCOUNT_PROVIDER: buildEndpoint('banks/bank-accounts-provider/:id/edit'),
        UPDATE_BANK_ACCOUNT_PROVIDER: buildEndpoint('banks/bank-accounts-provider/:id/update'),
        DELETE_BANK_ACCOUNT_PROVIDER: buildEndpoint('banks/bank-accounts-provider/:id/delete'),
        ACTIVE_BANK_ACCOUNT_PROVIDER: buildEndpoint('banks/bank-accounts-provider/:id/active'),
    },
    BANK_ACCOUNT_SOCIETY: {
        GET_BANK_ACCOUNT_SOCIETY_BY_SOCIETY: buildEndpoint('banks/bank-accounts-society/:society_id/by-society'),
        CREATE_BANK_ACCOUNT_SOCIETY: buildEndpoint('banks/bank-accounts-society/create'),
        STORE_BANK_ACCOUNT_SOCIETY: buildEndpoint('banks/bank-accounts-society/store'),
        EDIT_BANK_ACCOUNT_SOCIETY: buildEndpoint('banks/bank-accounts-society/:id/edit'),
        UPDATE_BANK_ACCOUNT_SOCIETY: buildEndpoint('banks/bank-accounts-society/:id/update'),
        DELETE_BANK_ACCOUNT_SOCIETY: buildEndpoint('banks/bank-accounts-society/:id/delete'),
        ACTIVE_BANK_ACCOUNT_SOCIETY: buildEndpoint('banks/bank-accounts-society/:id/active'),
    },
    BANK_ACCOUNT_BUSINESS_NAME: {
        GET_ALL_BUSINESS_NAME_BANK_ACCOUNTS: buildEndpoint('banks/bank-accounts'),
        GET_ALL_BUSINESS_NAME_BANK_ACCOUNTS_BY_BUSINESS_NAME: buildEndpoint('banks/bank-accounts/:business_name_id/by-business-name'),
        GET_ALL_BUSINESS_NAME_BANK_ACCOUNTS_BY_CLIENT: buildEndpoint('banks/bank-accounts/:client_id/by-client'),
        GET_ALL_BUSINESS_NAME_BANK_ACCOUNTS_BY_PROVIDER: buildEndpoint('banks/bank-accounts/:provider_id/by-provider'),
        GET_ALL_BUSINESS_NAME_BANK_ACCOUNTS_BY_SOCIETY: buildEndpoint('banks/bank-accounts/:society_id/by-society'),
        CREATE_BUSINESS_NAME_BANK_ACCOUNT: buildEndpoint('banks/bank-accounts/create'),
        STORE_BUSINESS_NAME_BANK_ACCOUNT: buildEndpoint('banks/bank-accounts/store'),
        EDIT_BUSINESS_NAME_BANK_ACCOUNT: buildEndpoint('banks/bank-accounts/:id/edit'),
        UPDATE_BUSINESS_NAME_BANK_ACCOUNT: buildEndpoint('banks/bank-accounts/:id/update'),
        DELETE_BUSINESS_NAME_BANK_ACCOUNT: buildEndpoint('banks/bank-accounts/:id/delete'),
        ACTIVE_BUSINESS_NAME_BANK_ACCOUNT: buildEndpoint('banks/bank-accounts/:id/active'),
        SHOW_BUSINESS_NAME_BANK_ACCOUNT: buildEndpoint('banks/bank-accounts/:id/show'),
        GET_BUSINESS_NAME_BANK_ACCOUNT_BY_BUSINESS_NAME_AND_BANK_ACCOUNT_REASON: buildEndpoint(
            'banks/bank-accounts/:business_name_id/:bank_account_reason_id/by-business-account'),
    },
    
    CONTACT_TYPE: {
        GET_CONTACT_TYPES: buildEndpoint('contacts/contact-types'),
        STORE_CONTACT_TYPE: buildEndpoint('contacts/contact-types/store'),
        EDIT_CONTACT_TYPE: buildEndpoint('contacts/contact-types/:id/edit'),
        CREATE_CONTACT_TYPE: buildEndpoint('contacts/contact-types/create'),
        SHOW_CONTACT_TYPE: buildEndpoint('contacts/contact-types/:id/show'),
        UPDATE_CONTACT_TYPE: buildEndpoint('contacts/contact-types/:id/update'),
        DELETE_CONTACT_TYPE: buildEndpoint('contacts/contact-types/:id/delete'),
        ACTIVE_CONTACT_TYPE: buildEndpoint('contacts/contact-types/:id/active'),
    },
    PROVIDER_CONTACT: {
        GET_PROVIDER_CONTACTS_BY_PROVIDER: buildEndpoint('contacts/provider-contacts/:provider_id/by-provider'),
        CREATE_PROVIDER_CONTACT: buildEndpoint('contacts/provider-contacts/create'),
        STORE_PROVIDER_CONTACT: buildEndpoint('contacts/provider-contacts/store'),
        EDIT_PROVIDER_CONTACT: buildEndpoint('contacts/provider-contacts/:id/edit'),
        SHOW_PROVIDER_CONTACT: buildEndpoint('contacts/provider-contacts/:id/show'),
        UPDATE_PROVIDER_CONTACT: buildEndpoint('contacts/provider-contacts/:id/update'),
        DELETE_PROVIDER_CONTACT: buildEndpoint('contacts/provider-contacts/:id/delete'),
        ACTIVE_PROVIDER_CONTACT: buildEndpoint('contacts/provider-contacts/:id/active'),
        GET_PROVIDER_CONTACT_BY_PROVIDER: buildEndpoint('contacts/provider-contacts/:provider_id/get-by-provider'),
    },
    SOCIETY_CONTACT: {
        GET_SOCIETY_CONTACTS_BY_SOCIETY: buildEndpoint('contacts/society-contacts/:society_id/by-society'),
        CREATE_SOCIETY_CONTACT: buildEndpoint('contacts/society-contacts/create'),
        STORE_SOCIETY_CONTACT: buildEndpoint('contacts/society-contacts/store'),
        EDIT_SOCIETY_CONTACT: buildEndpoint('contacts/society-contacts/:id/edit'),
        SHOW_SOCIETY_CONTACT: buildEndpoint('contacts/society-contacts/:id/show'),
        UPDATE_SOCIETY_CONTACT: buildEndpoint('contacts/society-contacts/:id/update'),
        DELETE_SOCIETY_CONTACT: buildEndpoint('contacts/society-contacts/:id/delete'),
        ACTIVE_SOCIETY_CONTACT: buildEndpoint('contacts/society-contacts/:id/active'),
    },
    USER: {
        GET_USERS: buildEndpoint('auths/users'),
        GET_USER: buildEndpoint('auths/users/:id/get'),
        STORE_USER: buildEndpoint('auths/users/store'),
        EDIT_USER: buildEndpoint('auths/users/:id/edit'),
        EDIT_PASSWORD_USER: buildEndpoint('auths/users/:id/edit-password'),
        CREATE_USER: buildEndpoint('auths/users/create'),
        SHOW_USER: buildEndpoint('auths/users/:id/show'),
        UPDATE_USER: buildEndpoint('auths/users/:id/update'),
        UPDATE_PASSWORD_USER: buildEndpoint('auths/users/:id/update-password'),
        DELETE_USER: buildEndpoint('auths/users/:id/delete'),
        ACTIVE_USER: buildEndpoint('auths/users/:id/active')
    },
    SALES_INVOICE: {
        GET_SALES_INVOICES: buildEndpoint('sales-invoices/sales-invoices'),
        STORE_SALES_INVOICE: buildEndpoint('sales-invoices/sales-invoices/store'),
        EDIT_SALES_INVOICE: buildEndpoint('sales-invoices/sales-invoices/:id/edit'),
        CREATE_SALES_INVOICE: buildEndpoint('sales-invoices/sales-invoices/create'),
        SHOW_SALES_INVOICE: buildEndpoint('sales-invoices/sales-invoices/:id/show'),
        UPDATE_SALES_INVOICE: buildEndpoint('sales-invoices/sales-invoices/:id/update'),
        DELETE_SALES_INVOICE: buildEndpoint('sales-invoices/sales-invoices/:id/delete'),
        ACTIVE_SALES_INVOICE: buildEndpoint('sales-invoices/sales-invoices/:id/active'),
        GET_SALES_INVOICE: buildEndpoint('sales-invoices/sales-invoices/:id/get'),
        GET_SALES_INVOICE_DETAILS: buildEndpoint('sales-invoices/sales-invoices/:id/details'),
        REVIEW: buildEndpoint('sales-invoices/sales-invoices/:id/change-to-review'),
        ACCOUNTED: buildEndpoint('sales-invoices/sales-invoices/:id/change-to-accounted'),
        ANNULLED: buildEndpoint('sales-invoices/sales-invoices/:id/change-to-annulled'),
        GET_PAYMENT_STATUSES_BY_CLIENT_SOCIETY: buildEndpoint('sales-invoices/sales-invoices/payment-statuses/by-society-client/:society_id/:client_id'),
    },

    SALES_INVOICE_DETAIL: {

        GET_SALES_INVOICE_DETAILS_BY_SALES_INVOICE: buildEndpoint('sales-invoices/sales-invoice-detail/:id/by-sales-invoice'),
        DELETE_SALES_INVOICE_BY_SALES_INVOICE: buildEndpoint('sales-invoices/sales-invoice-detail/:id/delete/:payment_status_id')
    },

    
    SALES_INVOICE_HISTORY: {

        GET_SALES_INVOICE_HISTORIES_BY_INVOICE: buildEndpoint('sales-invoices/sales-invoice-history/:sales_invoice_id'),
    }

    
};
