import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { UnitConversion, UnitConversionFilters } from '../../models/UnitConversion';

const useUnitConversionService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetUnitConversions, setFetchingGetUnitConversions] = useState(false);
    const [fetchingShowUnit, setFetchingShowUnitConversion] = useState(false);
    const [fetchingCreateUnitConversion, setFetchingCreateUnitConversion] = useState(false);
    const [fetchingStoreUnitConversion, setFetchingStoreUnitConversion] = useState(false);
    const [fetchingEditUnitConversion, setFetchingEditUnitConversion] = useState(false);
    const [fetchingUpdateUnitConversion, setFetchingUpdateUnitConversion] = useState(false);
    const [fetchingDeleteUnitConversion, setFetchingDeleteUnitConversion] = useState(false);
  

    const getUnitConversions = (filters: UnitConversionFilters, events: ServiceEvents = {}) => {

        const queryString = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.UNIT_CONVERSIONS.GET_UNIT_CONVERSIONS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetUnitConversions
        });
    };

    const showUnitConversion = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.UNIT_CONVERSIONS.SHOW_UNIT_CONVERSION.replace(':id', id.toString()),
            setFetching: setFetchingShowUnitConversion
        });
    };

    const createUnitConversion = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.UNIT_CONVERSIONS.CREATE_UNIT_CONVERSION,
            setFetching: setFetchingCreateUnitConversion
        });
    };

    const storeUnitConversion = (unitConversion: UnitConversion, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.UNIT_CONVERSIONS.STORE_UNIT_CONVERSION,
            body: unitConversion,
            setFetching: setFetchingStoreUnitConversion
        });
    };

    const editUnitConversion = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.UNIT_CONVERSIONS.EDIT_UNIT_CONVERSION.replace(':id', id.toString()),
            setFetching: setFetchingEditUnitConversion
        });
    };

    const updateUnitConversion = (id: number, unitConversion: UnitConversion, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.UNIT_CONVERSIONS.UPDATE_UNIT_CONVERSION.replace(':id', id.toString()),
            body: unitConversion,
            setFetching: setFetchingUpdateUnitConversion
        });
    };

    const deleteUnitConversion = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.UNIT_CONVERSIONS.DELETE_UNIT_CONVERSION.replace(':id', id.toString()),
            setFetching: setFetchingDeleteUnitConversion
        });
    };

    return {
        fetchingGetUnitConversions,
        fetchingShowUnit,
        fetchingStoreUnitConversion,
        fetchingCreateUnitConversion,
        fetchingEditUnitConversion,
        fetchingUpdateUnitConversion,
        fetchingDeleteUnitConversion,
        getUnitConversions,
        showUnitConversion,
        createUnitConversion,
        storeUnitConversion,
        editUnitConversion,
        updateUnitConversion,
        deleteUnitConversion
    };
};

export default useUnitConversionService;
