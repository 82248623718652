import React, { useContext } from 'react';
import useDispatchProgramService from '../../../app/services/hooks/useDispatchProgramService';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { DispatchProgramStatus } from '../../../app/models/DispatchProgram';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { label } from 'yet-another-react-lightbox/*';

interface Props {
    dispatchProgramId: number;
    statuses: DispatchProgramStatus[];
    selectedStatus: DispatchProgramStatus;
    callbackSuccess?: () => void;
}

const DispatchProgramChangeStatus = ({
    dispatchProgramId,
    statuses,
    selectedStatus,
    callbackSuccess
}: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const [ selected, setSelected ] = React.useState(selectedStatus);

    const { canChangeStatusDispatchProgram, changeStatusDispatchProgram,
        changeToDispatchStatusDispatchProgram,
        changeToFinishStatusDispatchProgram,
     } = useDispatchProgramService();

    // const requestChangeStatus = (valueStatus: string) => {
    //     if (showLoading) showLoading('loading', 'Cargando...');
        
    //     if (valueStatus == selected.id){
    //         if (hideLoading) hideLoading();
    //         return null;
    //     }

    //     const _status = statuses.find((status: DispatchProgramStatus) => status.id === valueStatus);

    //     if(!_status){
    //         if (hideLoading) hideLoading();
    //         return null;
    //     }
    //     setSelected(_status);

    //     canChangeStatusDispatchProgram(dispatchProgramId, valueStatus, {
    //         onSuccess: (response: ServiceResponse) => {
    //             if (hideLoading) hideLoading();
    //             changeStatus(valueStatus, response.message);
    //         },
    //         onError: (response: ServiceResponse) => {
    //             setSelected(selectedStatus);
    //             if (hideLoading) hideLoading();
    //             useReactConfirmAlert().errorAlert({
    //                 title: 'Error',
    //                 message: response.message,
    //                 message_list: response.data?.message_list ?? []
    //             });
    //             toast.error(response.message);
    //         }
    //     })
        
    // }

    const changeStatusToDispatch = (_status: any) => {
        const status_value = _status ? _status.name : '';
        const _text = `Está a punto de cambiar el estado del despacho #${dispatchProgramId} a ${status_value}.`;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, cambiar estado',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Cambiando estado...');
                changeToDispatchStatusDispatchProgram(dispatchProgramId, _status.id, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();

                        setSelected(_status ?? selected);

                        if (callbackSuccess) callbackSuccess();
                        toast.success(response.message);
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();
                        setSelected(selected);
                        toast.error(response.message);
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El estado del pedido no se ha cambiado.'
                });
                setSelected(selectedStatus);
            }
        });
    };

    const changeStatusToFinish = (_status: any) => {
        const status_value = _status ? _status.name : '';
        const _text = `Está a punto de cambiar el estado del despacho #${dispatchProgramId} a ${status_value}.`;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, cambiar estado',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Cambiando estado...');
                changeToFinishStatusDispatchProgram(dispatchProgramId, _status.id, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();

                        setSelected(_status ?? selected);

                        if (callbackSuccess) callbackSuccess();
                        toast.success(response.message);
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();
                        setSelected(selected);
                        toast.error(response.message);
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El estado del pedido no se ha cambiado.'
                });
                setSelected(selectedStatus);
            }
        });
    };

    const changeStatusToOther = (_status: any) => {
        const status_value = _status ? _status.name : '';
        const _text = `Está a punto de cambiar el estado del despacho #${dispatchProgramId} a ${status_value}.`;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, cambiar estado',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Cambiando estado...');
                changeStatusDispatchProgram(dispatchProgramId, _status.id, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();

                        setSelected(_status ?? selected);

                        if (callbackSuccess) callbackSuccess();
                        toast.success(response.message);
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();
                        setSelected(selected);
                        toast.error(response.message);
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El estado del pedido no se ha cambiado.'
                });
                setSelected(selectedStatus);
            }
        });
    };

    const changeStatus = (id: number, value: string) => {
        if (value == selected.id) return null;
        const _status = statuses.find((status: DispatchProgramStatus) => status.id == value);

        if(_status?.id == 'DESPACHO'){ 
            changeStatusToDispatch(_status);
        }else if(_status?.id == 'FINALIZADO') {
            changeStatusToFinish(_status);
        }else{
            changeStatusToOther(_status);
        }
    }

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => changeStatus(dispatchProgramId, String(e.target.value))}
        >
            <option value={selectedStatus.id}>{selectedStatus.name}</option>
            {statuses
                .filter((status) => status.id !== selectedStatus.id) 
                .map((status) => {
                    return (
                        <option key={status.id} value={status.id}>
                            {status.name}
                        </option>
                    );
                })}
        </select>
    );
};

export default DispatchProgramChangeStatus;
