import React, { useEffect } from 'react';
import { Product } from '../../../../app/models/Product';
import { Place } from '../../../../app/models/Place';
import { Unit } from '../../../../app/models/Unit';
import { Service } from '../../../../app/models/Service';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { setCleanInputError, setCleanTextAreaError } from '../../../../utils/utils';
import { Currency } from '../../../../app/models/Currency';
import { TransportationQuoteDetailDataForm } from '../../../../app/models/Quote';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';

import { RouteSegment } from '../../../../app/models/RouteSegment';
import { QuoteRouteSegment } from '../../../../app/models/QuoteRouteSegment';
import LazyLoading from '../../../../components/LazyLoading';

interface QuoteDetailsProps {
    quoteDetailForm: TransportationQuoteDetailDataForm;
    setQuoteDetailForm: (quoteDetail: TransportationQuoteDetailDataForm) => void;
    products: Product[];
    quantityUnits: Unit[];
    currencies: Currency[];
    origins: Place[];
    destinies: Place[];
    services: Service[];
    routeSegments: RouteSegment[];
    quoteRouteSegments: QuoteRouteSegment[];
    errorFields?: any;
    fetching: boolean;
    disableUpdate?: boolean;
}

const QuoteDetailForm = ({
    quoteDetailForm,
    setQuoteDetailForm,
    products,
    quantityUnits,
    currencies,
    origins,
    destinies,
    services,
    routeSegments,
    errorFields,
    quoteRouteSegments,
    fetching,
    disableUpdate = false
}: QuoteDetailsProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    // const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    //     const obj = {quote_route_segment_id : event.target.value}
    //     setQuoteDetailForm({ ...quoteDetailForm, ...obj });
    //     setSelectedRowId(event.target.value);

    // };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setQuoteDetailForm({ ...quoteDetailForm, [name]: value });
    };

    const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (String(name) == 'rate') {
            const foundCurrency = currencies.find(
                (item) => item.id === quoteDetailForm.currency_id
            );
            const currency = foundCurrency ? foundCurrency.name : '';
            if (currency === 'CLP') {
                setQuoteDetailForm({ ...quoteDetailForm, [name]: value.replace(/\D/g, '') });

                return;
            }
        } else if (String(name) == 'rate_runner') {
            const foundCurrency = currencies.find(
                (item) => item.id === quoteDetailForm.currency_rate_runner_id
            );
            const currency = foundCurrency ? foundCurrency.name : '';
            if (currency === 'CLP') {
                setQuoteDetailForm({ ...quoteDetailForm, [name]: value.replace(/\D/g, '') });

                return;
            }
        } else if (String(name) == 'rate_transporter') {
            const foundCurrency = currencies.find(
                (item) => item.id === quoteDetailForm.currency_rate_transporter_id
            );
            const currency = foundCurrency ? foundCurrency.name : '';
            if (currency === 'CLP') {
                setQuoteDetailForm({ ...quoteDetailForm, [name]: value.replace(/\D/g, '') });

                return;
            }
        }
        setQuoteDetailForm({ ...quoteDetailForm, [name]: value });
    };

    const handleChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        setQuoteDetailForm({ ...quoteDetailForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if (name == 'currency_id') {
            obj.rate = format_currency(quoteDetailForm.rate, value);
        } else if (name == 'currency_rate_runner_id') {
            obj.rate_runner = format_currency(quoteDetailForm.rate_runner, value);
        } else if (name == 'currency_rate_transporter_id') {
            obj.rate_transporter = format_currency(quoteDetailForm.rate_transporter, value);
        }

        setQuoteDetailForm({ ...quoteDetailForm, ...obj });
    };

    const format_currency = (currency_rate: any, value: any) => {
        let rate = String(currency_rate);

        const foundCurrency = currencies.find((item) => item.id === value);
        const currency = foundCurrency ? foundCurrency.name : '';

        if (currency_rate && currency === 'CLP') {
            const indice = rate.search(/\D/);

            if (indice !== -1) {
                rate = rate.substring(0, indice);
            }
        }

        return rate;
    };

    if (!quoteDetailForm) return null;

    return (
        <>
            {!disableUpdate && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Servicio</label>
                    <div className="col-md-9">
                        <SelectTwo
                            name="service_id"
                            id="service_id"
                            inputValue={quoteDetailForm.service_id}
                            hasError={fieldHasError('service_id') !== ''}
                            options={SelectTwoMapperOptions(services)}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('service_id', value.value)
                            }
                            placeholder={'Seleccione un servicio'}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('service_id')}
                        </div>
                    </div>
                </div>
            )}
            {!disableUpdate && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Producto</label>
                    <div className="col-md-9">
                        <SelectTwo
                            name="product_id"
                            id="product_id"
                            inputValue={quoteDetailForm.product_id}
                            hasError={fieldHasError('product_id') !== ''}
                            options={SelectTwoMapperOptions(products)}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('product_id', value.value)
                            }
                            placeholder={'Seleccione un producto'}
                            onFocus={() => onFocusRemove('product_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('product_id')}
                        </div>
                    </div>
                </div>
            )}

            {!disableUpdate && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Fecha de Despacho</label>
                    <div className="col-md-9">
                        <input
                            type="date"
                            className={`form-control ${fieldHasError('first_dispatch_date')}`}
                            name="first_dispatch_date"
                            id="first_dispatch_date"
                            value={quoteDetailForm.first_dispatch_date}
                            onChange={handleChange}
                            placeholder="Ingrese una fecha de despacho"
                            onFocus={() => onFocusRemove('first_dispatch_date')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('first_dispatch_date')}
                        </div>
                    </div>
                </div>
            )}
            {!disableUpdate && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Descripción</label>
                    <div className="col-md-9">
                        <textarea
                            className={`form-control ${fieldHasError('description')}`}
                            name="description"
                            id="description"
                            value={quoteDetailForm.description}
                            onChange={handleChangeText}
                            placeholder="Ingrese una descripción"
                            onFocus={setCleanTextAreaError}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('description')}
                        </div>
                    </div>
                </div>
            )}
            <hr />
            {fetching ? (
                <LazyLoading height={300} />
            ) : (
                <>
                    {!disableUpdate && (
                        <div className="mt-4 mb-2 row">
                            <label className="col-md-3 col-form-label">Tramo</label>
                            <div className="col-md-9">
                                <SelectTwo
                                    name="route_segment_id"
                                    id="route_segment_id"
                                    inputValue={quoteDetailForm.route_segment_id}
                                    hasError={fieldHasError('route_segment_id') !== ''}
                                    options={SelectTwoMapperOptions(routeSegments)}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('route_segment_id', value.value)
                                    }
                                    placeholder={'Seleccione un tramo'}
                                    onFocus={() => onFocusRemove('route_segment_id')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('route_segment_id')}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Cantidad</label>
                        <div className="col-md-9">
                            <div className="row g-1">
                                <div className="col-6">
                                    <input
                                        type="number"
                                        name="quantity"
                                        id="quantity"
                                        onFocus={() => onFocusRemove('quantity')}
                                        className={`form-control ${fieldHasError('quantity')}`}
                                        value={quoteDetailForm.quantity}
                                        onChange={handleChange}
                                        placeholder="Ingrese cantidad"
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('quantity')}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <SelectTwo
                                        name="quantity_unit_id"
                                        id="quantity_unit_id"
                                        hasError={fieldHasError('quantity_unit_id') !== ''}
                                        inputValue={quoteDetailForm.quantity_unit_id}
                                        options={SelectTwoMapperOptions(quantityUnits)}
                                        onChange={(value: any) =>
                                            handleChangeSelectTwo('quantity_unit_id', value.value)
                                        }
                                        placeholder={'medida'}
                                        onFocus={() => onFocusRemove('quantity_unit_id')}
                                        disabled={disableUpdate}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('quantity_unit_id')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!disableUpdate && (
                        <>
                            <div className=" row">
                                <label className="col-md-3 col-form-label">Tarifa de venta</label>
                                <div className="col-md-9 ">
                                    <div className="row g-1">
                                        <div className="col-6">
                                            <input
                                                type="number"
                                                name="rate"
                                                className={`form-control ${fieldHasError('rate')}`}
                                                id="rate"
                                                onFocus={() => onFocusRemove('rate')}
                                                value={quoteDetailForm.rate}
                                                onChange={handleRateChange}
                                                placeholder="Ingrese tarifa"
                                            />
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'flex' }}
                                            >
                                                {fieldErrorMessage('rate')}
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <SelectTwo
                                                name="currency_id"
                                                id="currency_id"
                                                inputValue={quoteDetailForm.currency_id}
                                                options={SelectTwoMapperOptions(currencies)}
                                                hasError={fieldHasError('currency_id') !== ''}
                                                onChange={(value: any) =>
                                                    handleChangeSelectTwo(
                                                        'currency_id',
                                                        value.value
                                                    )
                                                }
                                                placeholder={'moneda'}
                                                onFocus={() => onFocusRemove('currency_id')}
                                            />
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'flex' }}
                                            >
                                                {fieldErrorMessage('currency_id')}
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <SelectTwo
                                                name="rate_unit_id"
                                                id="rate_unit_id"
                                                inputValue={quoteDetailForm.rate_unit_id}
                                                options={SelectTwoMapperOptions(quantityUnits)}
                                                hasError={fieldHasError('rate_unit_id') !== ''}
                                                onChange={(value: any) =>
                                                    handleChangeSelectTwo(
                                                        'rate_unit_id',
                                                        value.value
                                                    )
                                                }
                                                onFocus={() => onFocusRemove('rate_unit_id')}
                                                placeholder={'unidad'}
                                            />
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'flex' }}
                                            >
                                                {fieldErrorMessage('rate_unit_id')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {!disableUpdate && (
                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Distancia KM</label>
                            <div className="col-md-9">
                                <input
                                    name="distance"
                                    id="distance"
                                    type="number"
                                    className={`form-control ${fieldHasError('distance')}`}
                                    value={quoteDetailForm.distance}
                                    onChange={handleChange}
                                    onFocus={() => onFocusRemove('distance')}
                                    placeholder="Ingrese distancia del tramo"
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('distance')}
                                </div>
                            </div>
                        </div>
                    )}
                    {!disableUpdate && (
                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Distancia acordada KM</label>
                            <div className="col-md-9">
                                <input
                                    name="agreed_distance"
                                    id="agreed_distance"
                                    type="number"
                                    className={`form-control ${fieldHasError('agreed_distance')}`}
                                    value={quoteDetailForm.agreed_distance}
                                    onChange={handleChange}
                                    onFocus={() => onFocusRemove('agreed_distance')}
                                    placeholder="Ingrese distancia acordada del tramo"
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('agreed_distance')}
                                </div>
                            </div>
                        </div>
                    )}
                    {!disableUpdate && (
                        <>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-form-label">Tarifa Runner</label>
                                <div className="col-md-9 ">
                                    <div className="row g-1">
                                        <div className="col-6">
                                            <input
                                                type="number"
                                                name="rate_runner"
                                                className={`form-control ${fieldHasError(
                                                    'rate_runner'
                                                )}`}
                                                id="rate_runner"
                                                onFocus={() => onFocusRemove('rate_runner')}
                                                value={quoteDetailForm.rate_runner}
                                                onChange={handleRateChange}
                                            />
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'flex' }}
                                            >
                                                {fieldErrorMessage('rate_runner')}
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <SelectTwo
                                                name="currency_rate_runner_id"
                                                id="currency_rate_runner_id"
                                                inputValue={quoteDetailForm.currency_rate_runner_id}
                                                options={SelectTwoMapperOptions(currencies)}
                                                hasError={
                                                    fieldHasError('currency_rate_runner_id') !== ''
                                                }
                                                onChange={(value: any) =>
                                                    handleChangeSelectTwo(
                                                        'currency_rate_runner_id',
                                                        value.value
                                                    )
                                                }
                                                onFocus={() =>
                                                    onFocusRemove('currency_rate_runner_id')
                                                }
                                                placeholder={'moneda'}
                                            />
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'flex' }}
                                            >
                                                {fieldErrorMessage('currency_rate_runner_id')}
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <SelectTwo
                                                name="quantity_unit_rate_runner_id"
                                                id="quantity_unit_rate_runner_id"
                                                inputValue={
                                                    quoteDetailForm.quantity_unit_rate_runner_id
                                                }
                                                options={SelectTwoMapperOptions(quantityUnits)}
                                                hasError={
                                                    fieldHasError(
                                                        'quantity_unit_rate_runner_id'
                                                    ) !== ''
                                                }
                                                onChange={(value: any) =>
                                                    handleChangeSelectTwo(
                                                        'quantity_unit_rate_runner_id',
                                                        value.value
                                                    )
                                                }
                                                onFocus={() =>
                                                    onFocusRemove('quantity_unit_rate_runner_id')
                                                }
                                                placeholder={'unidad'}
                                            />
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'flex' }}
                                            >
                                                {fieldErrorMessage('quantity_unit_rate_runner_id')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {!disableUpdate && (
                        <>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-form-label">
                                    Tarifa Transportista
                                </label>
                                <div className="col-md-9 ">
                                    <div className="row g-1">
                                        <div className="col-6">
                                            <input
                                                type="number"
                                                name="rate_transporter"
                                                className={`form-control ${fieldHasError(
                                                    'rate_transporter'
                                                )}`}
                                                id="rate_transporter"
                                                onFocus={() => onFocusRemove('rate_transporter')}
                                                value={quoteDetailForm.rate_transporter}
                                                onChange={handleRateChange}
                                            />
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'flex' }}
                                            >
                                                {fieldErrorMessage('rate_transporter')}
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <SelectTwo
                                                name="currency_rate_transporter_id"
                                                id="currency_rate_transporter_id"
                                                inputValue={
                                                    quoteDetailForm.currency_rate_transporter_id
                                                }
                                                options={SelectTwoMapperOptions(currencies)}
                                                hasError={
                                                    fieldHasError(
                                                        'currency_rate_transporter_id'
                                                    ) !== ''
                                                }
                                                onChange={(value: any) =>
                                                    handleChangeSelectTwo(
                                                        'currency_rate_transporter_id',
                                                        value.value
                                                    )
                                                }
                                                onFocus={() =>
                                                    onFocusRemove('currency_rate_transporter_id')
                                                }
                                                placeholder={'moneda'}
                                            />
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'flex' }}
                                            >
                                                {fieldErrorMessage('currency_rate_transporter_id')}
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <SelectTwo
                                                name="quantity_unit_rate_transporter_id"
                                                id="quantity_unit_rate_transporter_id"
                                                inputValue={
                                                    quoteDetailForm.quantity_unit_rate_transporter_id
                                                }
                                                options={SelectTwoMapperOptions(quantityUnits)}
                                                hasError={
                                                    fieldHasError(
                                                        'quantity_unit_rate_transporter_id'
                                                    ) !== ''
                                                }
                                                onChange={(value: any) =>
                                                    handleChangeSelectTwo(
                                                        'quantity_unit_rate_transporter_id',
                                                        value.value
                                                    )
                                                }
                                                onFocus={() =>
                                                    onFocusRemove(
                                                        'quantity_unit_rate_transporter_id'
                                                    )
                                                }
                                                placeholder={'unidad'}
                                            />
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'flex' }}
                                            >
                                                {fieldErrorMessage(
                                                    'quantity_unit_rate_transporter_id'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default QuoteDetailForm;
