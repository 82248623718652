import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useUnitConversionService from '../../../app/services/hooks/UseUnitConversionService';
import { defaultUnitConversionDataForm, UnitConversion } from '../../../app/models/UnitConversion';
import { Product } from '../../../app/models/Product';
import { Unit } from '../../../app/models/Unit';
import UnitConversionFormContainer from './UnitConversionFormContainer';


interface Props {
    unitConversionId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const UnitConversionEdit = ({ unitConversionId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditUnitConversion, editUnitConversion, fetchingUpdateUnitConversion, updateUnitConversion } =
        useUnitConversionService();

    const [unitConversion, setUnitConversion] = useState<UnitConversion>(defaultUnitConversionDataForm);
    const [products, setProducts] = useState<Product[]>([]);
    const [rateUnits, setRateUnits] = useState<Unit[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando conversión de unidad...');
        editUnitConversion(unitConversionId, {
            onSuccess: (response: ServiceResponse) => {

                setProducts(response.data.products);
                setRateUnits(response.data.rate_units);
                setQuantityUnits(response.data.quantity_units);
                setUnitConversion(response.data.unit_conversion);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando conversión de unidad...');
        updateUnitConversion(unitConversionId, unitConversion, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditUnitConversion ? (
        <LazyLoading height="300" />
    ) : (
        <UnitConversionFormContainer
            unitConversion={unitConversion}
            setUnitConversion={setUnitConversion}
            fetching={fetchingUpdateUnitConversion}
            action={update}
            cancel={cancel}
            products={products}
            rateUnits={rateUnits}
            quantityUnits={quantityUnits}
            errorFields={errorFields}
        />
    );
};

export default UnitConversionEdit;
