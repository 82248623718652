import useFetch from '../../../hooks/useFetch';
import { useContext, useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import {
    TransportationOrderDataForm,
    TransportationOrderFilters
} from '../../models/TransportationOrder';
import { QuoteFilters } from '../../models/Quote';
import { fetchDefaultHeaders } from '../shared/vars';
import { AppContext } from '../../../contexts/AppContext';

const useTransportationOrderService = () => {
    const { doPost, doGet, doPut, doDelete } = useFetch();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [fetchingGetTransportationOrders, setFetchingGetTransportationOrders] = useState(false);
    const [fetchingCreateTransportationOrder, setFetchingCreateTransportationOrder] =
        useState(false);
    const [fetchingStoreTransportationOrder, setFetchingStoreTransportationOrder] = useState(false);
    const [fetchingEditTransportationOrder, setFetchingEditTransportationOrder] = useState(false);
    const [fetchingCanEditTransportationOrder, setFetchingCanEditTransportationOrder] = useState(false);
    const [fetchingUpdateTransportationOrder, setFetchingUpdateTransportationOrder] =
        useState(false);
    const [fetchingDeleteTransportationOrder, setFetchingDeleteTransportationOrder] =
        useState(false);
    const [fetchingShowTransportationOrder, setFetchingShowTransportationOrder] = useState(false);

    const [fetchingShowQuoteTransportationOrder, setFetchingShowQuoteTransportationOrder] =
        useState(false);

    const [fetchingCanChangeStatusTransportationOrder, setFetchingCanChangeStatusTransportationOrder] = useState(false);
    const [fetchingChangeStatusTransportationOrder, setFetchingChangeStatusTransportationOrder] = useState(false);
    const [fetchingChangeStatusToActiveTransportationOrder, setFetchingChangeStatusToActiveTransportationOrder] = useState(false);
    const [fetchingChangeStatusToFinishTransportationOrder, setFetchingChangeStatusToFinishTransportationOrder] = useState(false);
    

    const getTransportationOrders = (
        transportationOrderFilters: TransportationOrderFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(transportationOrderFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.TRANSPORTATION_ORDERS.GET_TRANSPORTATION_ORDERS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetTransportationOrders
        });
    };

    const createTransportationOrder = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.CREATE_TRANSPORTATION_ORDER,
            setFetching: setFetchingCreateTransportationOrder
        });
    };

    const storeTransportationOrder = (
        quoteDataForm: TransportationOrderDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.STORE_TRANSPORTATION_ORDER,
            body: quoteDataForm,
            setFetching: setFetchingStoreTransportationOrder
        });
    };

    const editTransportationOrder = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.EDIT_TRANSPORTATION_ORDER.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingEditTransportationOrder
        });
    };

    const canEditTransportationOrder = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.CAN_EDIT_TRANSPORTATION_ORDER,
            setFetching: setFetchingCanEditTransportationOrder
        });
    };

    const updateTransportationOrder = (
        id: number,
        quoteDataFrom: TransportationOrderDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.UPDATE_TRANSPORTATION_ORDER.replace(
                ':id',
                id.toString()
            ),
            body: quoteDataFrom,
            setFetching: setFetchingUpdateTransportationOrder
        });
    };

    const updateTransportationOrderRateRunner = (
        id: number,
        quoteDataFrom: TransportationOrderDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.UPDATE_TRANSPORTATION_ORDER_RATE_RUNNER.replace(
                ':id',
                id.toString()
            ),
            body: quoteDataFrom,
            setFetching: setFetchingUpdateTransportationOrder
        });
    };

    const showTransportationOrder = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.SHOW_TRANSPORTATION_ORDER.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingShowTransportationOrder
        });
    };

    const deleteTransportationOrder = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.DELETE_TRANSPORTATION_ORDER.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingDeleteTransportationOrder
        });
    };

    const showQuoteTransportationOrder = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.SHOW_QUOTE_TRANSPORTATION_ORDER.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingShowQuoteTransportationOrder
        });
    };

    const getExcelAllTransportationOrders = (
        transportationOrderFilters: TransportationOrderFilters,
        events: ServiceEvents = {}
    ) => {
        if (showLoading) showLoading('download', 'descargando archivo...');

        let url = new URL(EndPoints.TRANSPORTATION_ORDERS.GET_EXCEL_TRANSPORTATION_ORDERS);
        url = getUrlTransportationOrderFilters(
            url,
            transportationOrderFilters.transportation_order_status,
            transportationOrderFilters.start_date,
            transportationOrderFilters.end_date,
            transportationOrderFilters.transportation_order_id,
            transportationOrderFilters.client,
            transportationOrderFilters.quote_id
        );

        fetch(url.href, { headers: { ...fetchDefaultHeaders } })
            .then((response) => {
                response
                    .blob()
                    .then((blob) => {
                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = 'pedidos.xlsx';
                        a.click();
                        window.URL.revokeObjectURL(url);
                        if (changeAnimation)
                            changeAnimation('downloadSuccess', 'descarga exitosa', true);
                    })
                    .catch((error) => {
                        if (changeAnimation) changeAnimation('error', 'descarga fallida', true);
                    });
            })
            .catch((error) => {
                if (changeAnimation) changeAnimation('error', 'descarga fallida', true);
            });
    };

    const getUrlTransportationOrderFilters = (
        url: URL,
        transportation_order_status: string | null = null,
        start_date: string | null = null,
        end_date: string | null = null,
        transportation_order_id: number | null = null,
        client: string | null = null,
        quote_id: number | null = null
    ) => {
        if (transportation_order_status && transportation_order_status !== 'TODOS') {
            url.searchParams.append('status', transportation_order_status);
        }
        if (start_date) {
            url.searchParams.append('start_date', start_date);
        }
        if (end_date) {
            url.searchParams.append('end_date', end_date);
        }
        if (transportation_order_id) {
            url.searchParams.append('id', String(transportation_order_id));
        }
        if (client) {
            url.searchParams.append('client', String(client));
        }
        if (quote_id) {
            url.searchParams.append('quote', String(quote_id));
        }
        return url;
    };

    const canChangeStatusTransportationOrder = (id: number, status: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.CAN_CHANGE_TRANSPORTATION_ORDER.replace(
                ':id',
                id.toString()
            ).replace(':status', status),
            setFetching: setFetchingCanChangeStatusTransportationOrder
        });
    };

    const changeStatusTransportationOrder = (id: number, status: string, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.CHANGE_STATUS_TRANSPORTATION_ORDER.replace(
                ':id',
                id.toString()
            ),
            body: { status: status },
            setFetching: setFetchingChangeStatusTransportationOrder
        });
    };

    const changeStatusToActiveTransportationOrder = (id: number, status: string, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.CHANGE_STATUS_TO_ACTIVE_TRANSPORTATION_ORDER.replace(
                ':id',
                id.toString()
            ),
            body: { status: status },
            setFetching: setFetchingChangeStatusToActiveTransportationOrder
        });
    };

    const changeStatusToFinishTransportationOrder = (id: number, status: string, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.CHANGE_STATUS_TO_FINISH_TRANSPORTATION_ORDER.replace(
                ':id',
                id.toString()
            ),
            body: { status: status },
            setFetching: setFetchingChangeStatusToFinishTransportationOrder
        });
    };


    return {
        fetchingGetTransportationOrders,
        fetchingDeleteTransportationOrder,
        fetchingCreateTransportationOrder,
        fetchingStoreTransportationOrder,
        fetchingEditTransportationOrder,
        fetchingCanEditTransportationOrder,
        fetchingUpdateTransportationOrder,
        fetchingShowTransportationOrder,
        fetchingShowQuoteTransportationOrder,
        fetchingCanChangeStatusTransportationOrder,
        fetchingChangeStatusTransportationOrder,
        fetchingChangeStatusToActiveTransportationOrder,
        fetchingChangeStatusToFinishTransportationOrder,
        getTransportationOrders,
        createTransportationOrder,
        updateTransportationOrderRateRunner,
        storeTransportationOrder,
        editTransportationOrder,
        canEditTransportationOrder,
        updateTransportationOrder,
        deleteTransportationOrder,
        showTransportationOrder,
        showQuoteTransportationOrder,
        getExcelAllTransportationOrders,
        canChangeStatusTransportationOrder,
        changeStatusTransportationOrder,
        changeStatusToActiveTransportationOrder,
        changeStatusToFinishTransportationOrder
    };
};

export default useTransportationOrderService;
