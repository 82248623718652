import { Product } from "./Product";
import { Unit } from "./Unit";

export interface UnitConversion{
    id?: number;
    product_cod?: string;
    product?: Product,
    product_id?: number;
    rate_unit?: Unit;
    rate_unit_id?: number;
    quantity_unit?: Unit;
    quantity_unit_id?: number;
    divisor?: number;
    is_active?: boolean;
    created_at?: Date | string;
    updated_at?: Date | string;
}


export interface UnitConversionFilters {
    id?: number | null;
    product_cod?: string | '';
    product?: string | '';
    rate_unit?: string | '';
    quantity_unit?: string | '';
    
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultUnitConversionDataForm = {
    product_cod: '',
    product_id: 0,
    rate_unit_id: 0,
    quantity_unit_id: 0,
    divisor: 1
}
