import React, { useEffect } from 'react';
import { Product } from '../../../../app/models/Product';
import { Place } from '../../../../app/models/Place';
import { Unit } from '../../../../app/models/Unit';
import { Service } from '../../../../app/models/Service';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { setCleanInputError, setCleanTextAreaError } from '../../../../utils/utils';
import { Currency } from '../../../../app/models/Currency';
import { TransportationOrderItemDataForm } from '../../../../app/models/TransportationOrderItem';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { RouteSegment, Routes } from '../../../../app/models/RouteSegment';
import { QuoteRouteSegment } from '../../../../app/models/QuoteRouteSegment';
import LazyLoading from '../../../../components/LazyLoading';

interface TransportationOrderItemProps {
    canBeEdited: boolean;
    transportationOrderItemForm: TransportationOrderItemDataForm;
    setTransportationOrderItemForm: (
        transportationOrderItemData: TransportationOrderItemDataForm
    ) => void;
    products: Product[];
    quantityUnits: Unit[];
    currencies: Currency[];
    services: Service[];
    routes: Routes[];
    hasQuote: boolean;
    errorFields?: any;
    disabledEdit?: boolean;
    fetching?: boolean;
    disabled?: boolean;
}

const TransportationOrderItemForm = ({
    canBeEdited,
    transportationOrderItemForm,
    setTransportationOrderItemForm,
    products,
    quantityUnits,
    currencies,
    services,
    routes,
    hasQuote,
    errorFields,
    disabledEdit = false,
    disabled = false,
    fetching
}: TransportationOrderItemProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setTransportationOrderItemForm({ ...transportationOrderItemForm, [name]: value });
    };

    const handleChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setTransportationOrderItemForm({ ...transportationOrderItemForm, [name]: value });
    };

    // const handleChangeSelectTwo = (name: string, value: number | string | null) => {
    //     setTransportationOrderItemForm({ ...transportationOrderItemForm, [name]: value });
    // };

    const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const foundCurrency = currencies.find(
            (item) => item.id === transportationOrderItemForm.currency_id
        );
        const currency = foundCurrency ? foundCurrency.name : '';
        if (currency === 'CLP') {
            setTransportationOrderItemForm({
                ...transportationOrderItemForm,
                [name]: value.replace(/\D/g, '')
            });

            return;
        }
        setTransportationOrderItemForm({ ...transportationOrderItemForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if (name == 'currency_id') {
            let rate = String(transportationOrderItemForm.rate);

            const foundCurrency = currencies.find((item) => item.id === value);
            const currency = foundCurrency ? foundCurrency.name : '';

            if (transportationOrderItemForm.rate && currency === 'CLP') {
                const indice = rate.search(/\D/);

                if (indice !== -1) {
                    rate = rate.substring(0, indice);

                    obj.rate = rate;
                }
            }
        }
        setTransportationOrderItemForm({ ...transportationOrderItemForm, ...obj });
    };

    const handleChangeSelectTwoRoute = (name: string, value: number | string | null) => {
        const obj: TransportationOrderItemDataForm = { [name]: value };
        console.log('obj', obj);
        if (name == 'currency_rate_runner_id') {
            obj.rate_runner = format_currency(transportationOrderItemForm.rate_runner, value);
        } else if (name == 'currency_rate_transporter_id') {
            obj.rate_transporter = format_currency(
                transportationOrderItemForm.rate_transporter,
                value
            );
        } else if (name == 'route_id') {
            const rateTransportAndRunner = routes.find((item) => item.id == value);
            if (rateTransportAndRunner) {
                obj.rate_runner = rateTransportAndRunner.rate_runner;
                obj.currency_rate_runner_id = rateTransportAndRunner.currency_rate_runner_id;
                obj.quantity_unit_rate_runner_id =
                    rateTransportAndRunner.quantity_unit_rate_runner_id;

                obj.rate_transporter = rateTransportAndRunner.rate_transporter;
                obj.currency_rate_transporter_id =
                    rateTransportAndRunner.currency_rate_transporter_id;
                obj.quantity_unit_rate_transporter_id =
                    rateTransportAndRunner.quantity_unit_rate_transporter_id;

                obj.rate = rateTransportAndRunner.rate;
                obj.currency_id = rateTransportAndRunner.currency_id;
            }
        }
        setTransportationOrderItemForm({ ...transportationOrderItemForm, ...obj });
    };

    const format_currency = (currency_rate: any, value: any) => {
        let rate = String(currency_rate);

        const foundCurrency = currencies.find((item) => item.id === value);
        const currency = foundCurrency ? foundCurrency.name : '';

        if (currency_rate && currency === 'CLP') {
            const indice = rate.search(/\D/);

            if (indice !== -1) {
                rate = rate.substring(0, indice);
            }
        }

        return rate;
    };

    if (!transportationOrderItemForm) return null;

    return (
        <>
            {canBeEdited && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Servicio</label>
                    <div className="col-md-9">
                        <SelectTwo
                            name="service_id"
                            id="service_id"
                            inputValue={transportationOrderItemForm.service_id}
                            hasError={fieldHasError('service_id') !== ''}
                            options={SelectTwoMapperOptions(services)}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('service_id', value.value)
                            }
                            onFocus={() => onFocusRemove('service_id')}
                            placeholder={'Seleccione un servicio'}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('service_id')}
                        </div>
                    </div>
                </div>
            )}

            {canBeEdited && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Producto</label>
                    <div className="col-md-9">
                        <SelectTwo
                            name="product_id"
                            id="product_id"
                            inputValue={transportationOrderItemForm.product_id}
                            hasError={fieldHasError('product_id') !== ''}
                            options={SelectTwoMapperOptions(products)}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('product_id', value.value)
                            }
                            placeholder={'Seleccione un producto'}
                            onFocus={() => onFocusRemove('product_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('product_id')}
                        </div>
                    </div>
                </div>
            )}

            {canBeEdited && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Fecha de Despacho</label>
                    <div className="col-md-9">
                        <input
                            type="date"
                            className={`form-control ${fieldHasError('dispatch_date')}`}
                            name="dispatch_date"
                            id="dispatch_date"
                            value={transportationOrderItemForm.dispatch_date}
                            onChange={handleChange}
                            placeholder="Ingrese una fecha de despacho"
                            onFocus={() => onFocusRemove('dispatch_date')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('dispatch_date')}
                        </div>
                    </div>
                </div>
            )}

            {canBeEdited && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Descripción</label>
                    <div className="col-md-9">
                        <textarea
                            className={`form-control ${fieldHasError('description')}`}
                            name="description"
                            id="description"
                            value={transportationOrderItemForm.description}
                            onChange={handleChangeText}
                            placeholder="Ingrese una descripción"
                            onFocus={setCleanTextAreaError}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('description')}
                        </div>
                    </div>
                </div>
            )}

            <hr />
            {fetching ? (
                <LazyLoading height={300} />
            ) : (
                <>
                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">
                            {hasQuote ? 'Opción ruta' : 'Tramo'}
                        </label>
                        <div className="col-md-9">
                            <SelectTwo
                                name="route_id"
                                id="route_id"
                                disabled={disabled}
                                inputValue={transportationOrderItemForm.route_id}
                                hasError={fieldHasError('route_id') !== ''}
                                options={SelectTwoMapperOptions(routes)}
                                onChange={(value: any) =>
                                    handleChangeSelectTwoRoute('route_id', value.value)
                                }
                                placeholder={
                                    hasQuote ? 'seleccione opcion ruta' : 'seleccione tramo'
                                }
                                onFocus={() => onFocusRemove('route_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('route_id')}
                            </div>
                        </div>
                    </div>

                    {/*{canBeEdited && (*/}
                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Tarifa venta</label>
                        <div className="col-md-9 ">
                            <div className="row g-1">
                                <div className="col-6">
                                    <input
                                        type="number"
                                        name="rate"
                                        className={`form-control ${fieldHasError('rate')}`}
                                        id="rate"
                                        disabled={disabled || disabledEdit}
                                        onFocus={() => onFocusRemove('rate')}
                                        value={transportationOrderItemForm.rate}
                                        onChange={handleRateChange}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('rate')}
                                    </div>
                                </div>

                                <div className="col-3">
                                    <SelectTwo
                                        name="currency_id"
                                        id="currency_id"
                                        inputValue={transportationOrderItemForm.currency_id}
                                        options={SelectTwoMapperOptions(currencies)}
                                        hasError={fieldHasError('currency_id') !== ''}
                                        onChange={(value: any) =>
                                            handleChangeSelectTwo('currency_id', value.value)
                                        }
                                        placeholder={'moneda'}
                                        onFocus={() => onFocusRemove('currency_id')}
                                        disabled={disabled || disabledEdit}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('currency_id')}
                                    </div>
                                </div>
                                <div className="col-3">
                                    <SelectTwo
                                        name="rate_unit_id"
                                        id="rate_unit_id"
                                        inputValue={transportationOrderItemForm.rate_unit_id}
                                        options={SelectTwoMapperOptions(quantityUnits)}
                                        hasError={fieldHasError('rate_unit_id') !== ''}
                                        onChange={(value: any) =>
                                            handleChangeSelectTwo('rate_unit_id', value.value)
                                        }
                                        placeholder={'unidad'}
                                        onFocus={() => onFocusRemove('rate_unit_id')}
                                        disabled={disabled || disabledEdit}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('rate_unit_id')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*)}*/}

                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Tarifa Runner</label>
                        <div className="col-md-9 ">
                            <div className="row g-1">
                                <div className="col-6">
                                    <input
                                        type="number"
                                        name="rate_runner"
                                        className={`form-control ${fieldHasError('rate_runner')}`}
                                        id="rate_runner"
                                        onFocus={() => onFocusRemove('rate_runner')}
                                        value={transportationOrderItemForm.rate_runner}
                                        onChange={handleRateChange}
                                        disabled={disabled || disabledEdit}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('rate_runner')}
                                    </div>
                                </div>

                                <div className="col-3">
                                    <SelectTwo
                                        name="currency_rate_runner_id"
                                        id="currency_rate_runner_id"
                                        inputValue={
                                            transportationOrderItemForm.currency_rate_runner_id
                                        }
                                        options={SelectTwoMapperOptions(currencies)}
                                        hasError={fieldHasError('currency_rate_runner_id') !== ''}
                                        onChange={(value: any) =>
                                            handleChangeSelectTwo(
                                                'currency_rate_runner_id',
                                                value.value
                                            )
                                        }
                                        onFocus={() => onFocusRemove('currency_rate_runner_id')}
                                        placeholder={'moneda'}
                                        disabled={disabled || disabledEdit}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('currency_rate_runner_id')}
                                    </div>
                                </div>
                                <div className="col-3">
                                    <SelectTwo
                                        name="quantity_unit_rate_runner_id"
                                        id="quantity_unit_rate_runner_id"
                                        inputValue={
                                            transportationOrderItemForm.quantity_unit_rate_runner_id
                                        }
                                        options={SelectTwoMapperOptions(quantityUnits)}
                                        hasError={
                                            fieldHasError('quantity_unit_rate_runner_id') !== ''
                                        }
                                        onChange={(value: any) =>
                                            handleChangeSelectTwo(
                                                'quantity_unit_rate_runner_id',
                                                value.value
                                            )
                                        }
                                        disabled={disabled || disabledEdit}
                                        onFocus={() =>
                                            onFocusRemove('quantity_unit_rate_runner_id')
                                        }
                                        placeholder={'unidad'}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('quantity_unit_rate_runner_id')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Tarifa Transportista</label>
                        <div className="col-md-9 ">
                            <div className="row g-1">
                                <div className="col-6">
                                    <input
                                        type="number"
                                        name="rate_transporter"
                                        className={`form-control ${fieldHasError(
                                            'rate_transporter'
                                        )}`}
                                        id="rate_transporter"
                                        onFocus={() => onFocusRemove('rate_transporter')}
                                        value={transportationOrderItemForm.rate_transporter}
                                        onChange={handleRateChange}
                                        disabled={disabled || disabledEdit}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('rate_transporter')}
                                    </div>
                                </div>

                                <div className="col-3">
                                    <SelectTwo
                                        name="currency_rate_transporter_id"
                                        id="currency_rate_transporter_id"
                                        inputValue={
                                            transportationOrderItemForm.currency_rate_transporter_id
                                        }
                                        options={SelectTwoMapperOptions(currencies)}
                                        hasError={
                                            fieldHasError('currency_rate_transporter_id') !== ''
                                        }
                                        onChange={(value: any) =>
                                            handleChangeSelectTwo(
                                                'currency_rate_transporter_id',
                                                value.value
                                            )
                                        }
                                        onFocus={() =>
                                            onFocusRemove('currency_rate_transporter_id')
                                        }
                                        placeholder={'moneda'}
                                        disabled={disabled || disabledEdit}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('currency_rate_transporter_id')}
                                    </div>
                                </div>
                                <div className="col-3">
                                    <SelectTwo
                                        name="quantity_unit_rate_transporter_id"
                                        id="quantity_unit_rate_transporter_id"
                                        inputValue={
                                            transportationOrderItemForm.quantity_unit_rate_transporter_id
                                        }
                                        options={SelectTwoMapperOptions(quantityUnits)}
                                        hasError={
                                            fieldHasError('quantity_unit_rate_transporter_id') !==
                                            ''
                                        }
                                        onChange={(value: any) =>
                                            handleChangeSelectTwo(
                                                'quantity_unit_rate_transporter_id',
                                                value.value
                                            )
                                        }
                                        disabled={disabled || disabledEdit}
                                        onFocus={() =>
                                            onFocusRemove('quantity_unit_rate_transporter_id')
                                        }
                                        placeholder={'unidad'}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('quantity_unit_rate_transporter_id')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Cantidad</label>
                        <div className="col-md-9">
                            <div className="row g-1">
                                <div className="col-6">
                                    <input
                                        type="number"
                                        name="quantity"
                                        id="quantity"
                                        onFocus={() => onFocusRemove('quantity')}
                                        className={`form-control ${fieldHasError('quantity')}`}
                                        value={transportationOrderItemForm.quantity}
                                        onChange={handleChange}
                                        disabled={disabled}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('quantity')}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <SelectTwo
                                        name="quantity_unit_id"
                                        id="quantity_unit_id"
                                        hasError={fieldHasError('quantity_unit_id') !== ''}
                                        inputValue={transportationOrderItemForm.quantity_unit_id}
                                        options={SelectTwoMapperOptions(quantityUnits)}
                                        onChange={(value: any) =>
                                            handleChangeSelectTwo('quantity_unit_id', value.value)
                                        }
                                        placeholder={'medida'}
                                        disabled={disabled}
                                        onFocus={() => onFocusRemove('quantity_unit_id')}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('quantity_unit_id')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {canBeEdited && (
                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Distancia acordada km</label>
                            <div className="col-md-9">
                                <input
                                    type="number"
                                    className={`form-control ${fieldHasError('agreed_distance')}`}
                                    name="agreed_distance"
                                    id="agreed_distance"
                                    value={transportationOrderItemForm.agreed_distance}
                                    onChange={handleChange}
                                    placeholder="Ingrese distancia acordada en Km"
                                    onFocus={() => onFocusRemove('agreed_distance')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('agreed_distance')}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default TransportationOrderItemForm;
