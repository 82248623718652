import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import QuoteFilter from './components/tables/QuoteFilter';
import QuoteDataTable from './components/tables/QuoteDataTable';
import { Quote, QuoteFilters } from '../../app/models/Quote';
import moment from 'moment';
import { QuoteStatus } from '../../app/models/QuoteStatus';
import useQuoteService from '../../app/services/hooks/useQuoteService';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import DefaultModal from '../../components/default/DefaultModal';
import { toast } from 'react-toastify';
import QuoteCreate from './components/QuoteCreate';
import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { useHistory } from 'react-router-dom';
import useSweetAlert from '../../hooks/useSweetAlert';
import { Roles } from '../../app/shared/enums';
import { AuthContext } from '../../contexts/AuthContext';
import { AppContext } from '../../contexts/AppContext';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Cotizaciones',
        url: '/quotes',
        isActive: true
    }
];

const Quotes = () => {
    const {
        getAllQuotes,
        getExcelAllQuotes,
        fetchingGetQuotes,
        deleteQuote,
        repeatQuote,
        getDocumentQuote
    } = useQuoteService();

    const [quotes, setQuotes] = useState<Quote[]>([]);
    const [statuses, setStatuses] = useState<QuoteStatus[]>([]);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);
    const { auth } = useContext(AuthContext);
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [filter, setFilter] = useState<QuoteFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    // const [filter, setFilter] = useState<QuoteFilters>({
    //     start_date: moment().subtract(5, 'years').format('YYYY-MM-DD'),
    //     end_date: moment().add(1, 'months').format('YYYY-MM-DD'),
    //     quote_id: null,
    //     client: '',
    //     quote_status: ''
    // });

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getAllQuotes(filter, {
            onSuccess: (response: ServiceResponse) => {
                setQuotes(response.data.quotes);
                setTotalRows(response.data.total_rows);
                setStatuses(response.data.quote_statuses);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/')
                }
            }
        });
    };

    const destroy = (quoteId: number) => {
        const _text = 'Está a punto de eliminar la cotización #' + quoteId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                deleteQuote(quoteId, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: 'Se ha eliminado la cotización #' + quoteId
                        });
                        reloadTable();
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: response.message
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'La cotización no se ha eliminado.'
                });
            }
        });
    };

    const repeat = (quoteId: number) => {
        const _text = 'Está a punto de duplicar la cotización #' + quoteId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, duplicar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                repeatQuote(quoteId, {
                    onSuccess: (response: ServiceResponse) => {
                        reloadTable();
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'La cotización no se ha repetido.'
                });
            }
        });
    };

    const document = (quoteId: number) => {
        getDocumentQuote(quoteId, {});
    };

    const downloadExcel = () => {
        getExcelAllQuotes(filter);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const { navigationPage } = useNavigationPage();

    const edit = (id?: number) => {
        navigationPage('/quotes/' + id + '/edit');
    };

    const show = (id?: number) => {
        navigationPage('/quotes/' + id + '/show');
    };

    return (
        <>
            <Breadcrumbs pageSection="Cotizaciones" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <ButtonCreate
                                    callbackCreate={showCreate}
                                    title="Nueva Cotización"
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <QuoteFilter filter={filter} setFilter={setFilter} />
                            </div>
                            <div className="col-auto" style={{ paddingTop: 22 }}>
                                <button className="btn btn-success btn-sm" onClick={downloadExcel}>
                                    <i className="fa fa-file-excel" /> Descargar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <QuoteDataTable
                            quotes={quotes}
                            statuses={statuses}
                            totalRows={totalRows}
                            loading={fetchingGetQuotes}
                            destroy={destroy}
                            edit={edit}
                            show={show}
                            repeat={repeat}
                            documentQuote={document}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Cotización"
                    backdrop={true}
                    showFooter={false}
                >
                    <QuoteCreate
                        onSaved={() => {
                            reloadTable();
                        }}
                        onCancel={hideCreate}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Quotes;
