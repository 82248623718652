import React from 'react';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { TransportationOrderItemStatus } from '../../../app/models/TransportationOrderItemStatus';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useTransportationOrderItemService from '../../../app/services/hooks/useTransportationOrderItemService';
import { TransportationOrderItemStatuses } from '../../../app/shared/enums';

interface Props {
    transportationOrderItemId: number;
    statuses: TransportationOrderItemStatus[];
    selectedStatus: TransportationOrderItemStatus;
    reloadModule?: () => void;
    callbackSuccess?: () => void;
}

const TransportationOrderItemChangeStatus = ({
    transportationOrderItemId,
    statuses,
    selectedStatus,
    callbackSuccess,
    reloadModule
}: Props) => {
    const [selected, setSelected] = React.useState<TransportationOrderItemStatus>(selectedStatus);

    const {
        changeToPreparationStatusTransportationOrderItem,
        changeToDispatchStatusTransportationOrderItem,
        changeToFinishStatusTransportationOrderItem
    } = useTransportationOrderItemService();

    // const hasFinished = (id: number, value: string) => {
    //     useSweetAlert().spinnerAlert('cargando...');
    //     if (value == selected.id) return null;

    //     const _status = statuses.find((status: TransportationOrderItemStatus) => status.id == value);

    //     HasAllFinishedTransportationOrderItems(id, value, {
    //         onSuccess: (response: ServiceResponse) => {
    //             useSweetAlert().closeSpinnerAlert();
    //             changeStatus(_status, response.data.message);
    //         },
    //         onError: (response: ServiceResponse) => {
    //             useSweetAlert().closeSpinnerAlert();
    //             setSelected(selected);
    //             useSweetAlert().errorAlert({
    //                 title: 'Error',
    //                 text: response.message
    //             });
    //         }
    //     });
    // };

    const changeStatusToPreparation = (_status: any) => {
        const status_value = _status ? _status.name : '';
        const _text = `Está a punto de cambiar el estado de la posición de pedido #${transportationOrderItemId} a ${status_value}.`;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, cambiar estado',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Cambiando estado...');
                changeToPreparationStatusTransportationOrderItem(transportationOrderItemId, _status.id, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();

                        setSelected(_status ?? selected);

                        if (reloadModule) reloadModule();
                        if (callbackSuccess) callbackSuccess();
                        toast.success(response.message);
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();
                        setSelected(selected);
                        toast.error(response.message);
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El estado del pedido no se ha cambiado.'
                });
                setSelected(selectedStatus);
            }
        });
    };

    const changeStatusToDispatch = (_status: any) => {
        const status_value = _status ? _status.name : '';
        const _text = `Está a punto de cambiar el estado de la posición de pedido #${transportationOrderItemId} a ${status_value}.`;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, cambiar estado',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Cambiando estado...');
                changeToDispatchStatusTransportationOrderItem(transportationOrderItemId, _status.id, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();

                        setSelected(_status ?? selected);

                        if (reloadModule) reloadModule();
                        if (callbackSuccess) callbackSuccess();
                        toast.success(response.message);
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();
                        setSelected(selected);
                        toast.error(response.message);
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El estado del pedido no se ha cambiado.'
                });
                setSelected(selectedStatus);
            }
        });
    };

    const changeStatusToFinish = (_status: any) => {
        const status_value = _status ? _status.name : '';
        const _text = `Está a punto de cambiar el estado de la posición de pedido #${transportationOrderItemId} a ${status_value}.`;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, cambiar estado',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Cambiando estado...');
                changeToFinishStatusTransportationOrderItem(transportationOrderItemId, _status.id, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();

                        setSelected(_status ?? selected);

                        if (reloadModule) reloadModule();
                        if (callbackSuccess) callbackSuccess();

                        toast.success(response.message);
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();
                        setSelected(selected);
                        toast.error(response.message);
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El estado del pedido no se ha cambiado.'
                });
                setSelected(selectedStatus);
            }
        });
    };

  
    const changeStatus = (value: string) => {
        if (value == selected.id) return null;
        const _status = statuses.find((status: TransportationOrderItemStatus) => status.id == value);

        if(_status?.id === TransportationOrderItemStatuses.PREPARACION){ 
            changeStatusToPreparation(_status);
        }
        else if(_status?.id === TransportationOrderItemStatuses.DESPACHO){ 
            changeStatusToDispatch(_status);
        }else if(_status?.id === TransportationOrderItemStatuses.FINALIZADO){
            changeStatusToFinish(_status);
        }
    }


    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => changeStatus(String(e.target.value))}
        >
            {statuses
                ? statuses.map((status) => {
                        return (
                            <option key={status.id} value={status.id}>
                                {status.name}
                            </option>
                        );
                    })
                : null}
        </select>
    );
};

export default TransportationOrderItemChangeStatus;
