import React from 'react'
import { GuideDataForm, GuideFilters } from '../../../../app/models/Guide';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { momentParseDate } from '../../../../helpers';
import GuideFilter from './GuideFilter';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';

interface Props {
    guides: GuideDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    download?: (id: number) => void;
    filter: GuideFilters;
    setFilter: (filter: GuideFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
}


const GuideDataTable = ({
    guides,
    totalRows,
    loading = false,
    edit,
    destroy,
    active,
    show,
    download,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: GuideDataForm, index: any, column: any) => <span className="">{row.id}</span>
        },
        {
            name: 'Fecha de Despacho',
            selector: (row: any) => row.actual_dispatch_date,
            sortable: true,
            sortField: 'actual_dispatch_date',
            cell: (row: GuideDataForm, index: any, column: any) => <span className="">{momentParseDate(row.actual_dispatch_date)}</span>
        },
        {
            name: 'Cliente',
            selector: (row: any) => row.client,
            sortable: true,
            sortField: 'client',
            cell: (row: GuideDataForm, index: any, column: any) => <span className="">{row.client}</span>
        },
        {
            name: 'Despacho',
            selector: (row: any) => row.dispatch_program,
            sortable: true,
            sortField: 'dispatch_program',
            cell: (row: GuideDataForm, index: any, column: any) => <span className="">{row.dispatch_program}</span>
        },
        {
            name: 'Tranportista',
            selector: (row: any) => row.transporter,
            sortable: true,
            sortField: 'transporter',
            cell: (row: GuideDataForm, index: any, column: any) => <span className="">{row.transporter}</span>
        },
        {
            name: 'Conductor',
            selector: (row: any) => row.carrier,
            sortable: true,
            sortField: 'carrier',
            cell: (row: GuideDataForm, index: any, column: any) => <span className="">{row.carrier}</span>
        },
        {
            name: 'Tracto',
            selector: (row: any) => row.truck,
            sortable: true,
            sortField: 'truck',
            cell: (row: GuideDataForm, index: any, column: any) => <span className="">{row.truck}</span>
        },
        {
            name: 'Folio',
            selector: (row: any) => row.folio,
            sortable: true,
            sortField: 'folio',
            cell: (row: GuideDataForm, index: any, column: any) => <span className="">{row.folio}</span>
        },
        {
            name: 'Tramo',
            selector: (row: any) => row.route_segment,
            sortable: true,
            sortField: 'id',
            cell: (row: GuideDataForm, index: any, column: any) => <span className="">{row.route_segment}</span>
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="nowrap-cell nowrap-cell-no-min">
                    {row.file ? (
                        <a
                            href={row.file}
                            className="text-success icon-pointer mx-2"
                            title={'Descargar Guía'}
                            download
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <i className="mdi mdi-download font-size-18" />
                        </a>
                    ) : (
                        <a
                            className="text-secondary icon-pointer mx-2 disabled"
                            title={'Descargar Guia'}
                        >
                            <i className="mdi mdi-download font-size-18 disabled" />
                        </a>
                    )}
                </div>
            )
        }
    ];


    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <GuideFilter
                        filter={filter}
                        setFilter={setFilter}
                    />
                </div>
            </div>
            <DefaultDataTable
                columns={columns}
                data={guides}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    )
}

export default GuideDataTable
