import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { UnitConversion } from '../../../app/models/UnitConversion';
import UnitConversionForm from './forms/UnitConversionForm';
import { Product } from '../../../app/models/Product';
import { Unit } from '../../../app/models/Unit';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    unitConversion: UnitConversion;
    setUnitConversion: (unitConversion: UnitConversion) => void;
    products: Product[];
    rateUnits: Unit[];
    quantityUnits: Unit[];
    errorFields?: any;
}

const UnitConversionFormContainer = ({
    fetching,
    action,
    cancel,
    unitConversion,
    setUnitConversion,
    products,
    rateUnits,
    quantityUnits,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <UnitConversionForm
                        unitConversionDataForm={unitConversion}
                        setUnitConversionDataForm={setUnitConversion}
                        products={products}
                        rateUnits={rateUnits}
                        quantityUnits={quantityUnits}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default UnitConversionFormContainer;
