import React from 'react';
import { TaxFilters } from '../../../../app/models/Tax';
import { UnitConversionFilters } from '../../../../app/models/UnitConversion';

type Props = {
    filter: UnitConversionFilters;
    setFilter: (filter: UnitConversionFilters) => void;
};

const UnitConversionFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="product_cod">
                            Cod Producto
                        </label>
                        <input
                            title="Cod Producto"
                            type="text"
                            id="product_cod"
                            name="product_cod"
                            className="form-control form-control-sm"
                            value={filter.product_cod}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="product">
                            Producto
                        </label>
                        <input
                            title="Producto"
                            type="text"
                            id="product"
                            name="product"
                            className="form-control form-control-sm"
                            value={filter.product}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="rate_unit">
                            Unidad de Tarifa
                        </label>
                        <input
                            title="Unidad de tarifa"
                            type="text"
                            id="rate_unit"
                            name="rate_unit"
                            className="form-control form-control-sm"
                            value={filter.rate_unit}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="quantity_unit">
                            Unidad de Cantidad
                        </label>
                        <input
                            title="Unidad de Cantidad"
                            type="text"
                            id="quantity_unit"
                            name="quantity_unit"
                            className="form-control form-control-sm"
                            value={filter.quantity_unit}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnitConversionFilter;
