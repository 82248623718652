import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { UnitConversion } from '../../../../app/models/UnitConversion';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Product } from '../../../../app/models/Product';
import { Unit } from '../../../../app/models/Unit';

type Props = {
    unitConversionDataForm: UnitConversion;
    setUnitConversionDataForm: (unitConversionDataForm: UnitConversion) => void;
    products: Product[];
    rateUnits: Unit[];
    quantityUnits: Unit[];
    errorFields?: any;
};

const UnitConversionForm = (
    { 
        unitConversionDataForm, 
        setUnitConversionDataForm,
        products,
        rateUnits,
        quantityUnits,
        errorFields 
    }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> |
           React.ChangeEvent<HTMLSelectElement> |
           React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setUnitConversionDataForm({ ...unitConversionDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setUnitConversionDataForm({ ...unitConversionDataForm, ...obj });
    };

    return (
        <>
          
                <div className="mb-2 row">
                    <div className="col-md-12">
                    <label className="col-form-label">Cod Producto</label>
                    
                        <input
                            name="product_cod"
                            id="product_cod"
                            type="text"
                            className={`form-control ${fieldHasError('product_cod')}`}
                            value={unitConversionDataForm.product_cod}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('product_cod')}
                            placeholder="Ingrese código producto"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('product_cod')}
                        </div>
                    </div>
                </div>

                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Producto</label>
                        <SelectTwo
                            name="product_id"
                            id="product_id"
                            inputValue={unitConversionDataForm.product_id}
                            options={SelectTwoMapperOptions(products, 'id', ['name'])}
                            hasError={fieldHasError('product_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('product_id', value.value)
                            }
                            placeholder={'Seleccione producto'}
                            onFocus={() => onFocusRemove('product_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('product_id')}
                        </div>
                    </div>
                </div>

                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Unidad de Tarifa</label>
                        <SelectTwo
                            name="rate_unit_id"
                            id="rate_unit_id"
                            inputValue={unitConversionDataForm.rate_unit_id}
                            options={SelectTwoMapperOptions(rateUnits, 'id', ['name'])}
                            hasError={fieldHasError('rate_unit_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('rate_unit_id', value.value)
                            }
                            placeholder={'Seleccione unidad tarifa'}
                            onFocus={() => onFocusRemove('rate_unit_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('rate_unit_id')}
                        </div>
                    </div>
                </div>

                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Unidad de Cantidad</label>
                        <SelectTwo
                            name="quantity_unit_id"
                            id="quantity_unit_id"
                            inputValue={unitConversionDataForm.quantity_unit_id}
                            options={SelectTwoMapperOptions(quantityUnits, 'id', ['name'])}
                            hasError={fieldHasError('quantity_unit_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('quantity_unit_id', value.value)
                            }
                            placeholder={'Seleccione unidad cantidad'}
                            onFocus={() => onFocusRemove('quantity_unit_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('quantity_unit_id')}
                        </div>
                    </div>
                </div>

                <div className="mb-2 row">
                    <div className="col-md-12">
                    <label className="col-form-label">Divisor</label>
                    
                        <input
                            name="divisor"
                            id="divisor"
                            type="number"
                            className={`form-control ${fieldHasError('divisor')}`}
                            value={unitConversionDataForm.divisor}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('divisor')}
                            placeholder="Ingrese divisor"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('divisor')}
                        </div>
                    </div>
                </div>
            
        </>
    );
};

export default UnitConversionForm;
